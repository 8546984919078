// ----------------------------------------------------------------------

const ROOTS = {
  HOME: '/home',
  CHAT: '/chat',
  PLANNING: '/planning',
  AUTH: '/auth',
  GUIDANCE: '/guidance',
  KANBAN: '/kanban',
  EXPENSES: '/expenses',
  INCOME: '/income',
  UNIT_ECONOMICS: '/unit-economics',
  SETTINGS: '/settings',
  USER: '/user'
};

// ----------------------------------------------------------------------

export const paths = {
  root: ROOTS.PLANNING,
  auth: {
    login: `${ROOTS.AUTH}/login`,
    register: `${ROOTS.AUTH}/register`,
    forgotPassword: `${ROOTS.AUTH}/forgot-password`,
    newPassword: `${ROOTS.AUTH}/new-password`,
    verify: `${ROOTS.AUTH}/verify`,
  },
  home: {
    root: ROOTS.HOME,
  },
  chat: {
    root: ROOTS.CHAT,
  },
  settings: {
    root: ROOTS.SETTINGS,
  },
  planning: {
    root: ROOTS.PLANNING,
  },
  user: {
    root: `${ROOTS.USER}`,
    new: `${ROOTS.USER}/new`,
    list: `${ROOTS.USER}/list`,
    cards: `${ROOTS.USER}/cards`,
    profile: (id: string) => `${ROOTS.USER}/${id}/profile`,
    account: `${ROOTS.USER}/account`,
    edit: (id: string) => `${ROOTS.USER}/${id}/edit`,
  },
};
