import { useContext, useMemo } from 'react';
import { paths } from 'src/routes/paths';
import { useLocales } from 'src/locales';
import SvgColor from 'src/components/svg-color';
import { AppContext } from 'src/context/app-context';

// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  chat: icon('ic_chat'),
  analytics: icon('ic_analytics'),
  expenses: icon('ic_invoice'),
  income: icon('ic_order'),
  unitEconomics: icon('ic_product'),
  dashboard: icon('ic_dashboard'),
  banking: icon('ic_banking'),
};

// ----------------------------------------------------------------------

export function useNavData() {
  const { t } = useLocales();

  const data = useMemo(
    () => [
      {
        subheader: t('overview'),
        items: [
          { title: t('Planning'), path: paths.planning.root, icon: ICONS.analytics },
          { title: t('KPIs'), path: paths.home.root, icon: ICONS.dashboard },
          { title: t('chat'), path: paths.chat.root, icon: ICONS.chat },
          { title: t('settings'), path: paths.settings.root, icon: ICONS.banking },
        ]
      }
    ],
    [t]
  );

  return data;
}
