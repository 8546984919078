import { createContext, useMemo, useState } from 'react';
import EventEmitter from 'eventemitter3';
import { IChatMessage, IChatParticipant } from 'src/types/chat';

interface AppContextType {
    selectedOrganizationId: string;
    setSelectedOrganizationId: (organization: string) => void;
    systemMessageEmitter: EventEmitter;
    messages: IChatMessage[];
    setMessages: (messages: IChatMessage[] | any) => void;
    participants: IChatParticipant[];
    setParticipants: (participants: IChatParticipant[]) => void;
}

const initialValue = {
    selectedOrganizationId: "",
    setSelectedOrganizationId: () => { },
    systemMessageEmitter: new EventEmitter(),
    messages: [],
    setMessages: () => { },
    participants: [],
    setParticipants: () => { }
}

export const AppContext = createContext<AppContextType>(initialValue);

interface Props {
    children: React.ReactNode;
}

const AppProvider: React.FC<Props> = ({ children }: Props) => {

    // System messages are push messages that are sent from the server on the chat open websocket
    const [systemMessageEmitter] = useState(initialValue.systemMessageEmitter);
    const [selectedOrganizationId, setSelectedOrganizationId] = useState(initialValue.selectedOrganizationId);
    const [ messages, setMessages ] = useState<IChatMessage[]>([]);
    const [ participants, setParticipants ] = useState<IChatParticipant[]>([]);

    const memoizedValue = useMemo(
        () => ({ 
            selectedOrganizationId,
            setSelectedOrganizationId,
            systemMessageEmitter,
            messages,
            setMessages,
            participants,
            setParticipants
        })
    , [systemMessageEmitter, selectedOrganizationId, setSelectedOrganizationId, messages, setMessages, participants, setParticipants]);

    return (
        <AppContext.Provider value={memoizedValue} >
            {children}
        </AppContext.Provider>
    );
};

export default AppProvider;
