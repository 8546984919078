import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { GuestGuard } from 'src/auth/guard';
// layouts
import AuthClassicLayout from 'src/layouts/auth/classic';
// components
import { SplashScreen } from 'src/components/loading-screen';
import AuthModernLayout from 'src/layouts/auth/modern';
import CompactLayout from 'src/layouts/compact/layout';
import VerifyPage from 'src/pages/auth/verify';
import NewPasswordPage from 'src/pages/auth/new-password';
import ForgotPasswordPage from 'src/pages/auth/forgot-password';

// ----------------------------------------------------------------------

// JWT
const AutoLoginPage = lazy(() => import('src/pages/auth/auto-login'));
const JwtLoginPage = lazy(() => import('src/pages/auth/login'));
const JwtRegisterPage = lazy(() => import('src/pages/auth/register'));

// ----------------------------------------------------------------------

const authJwt = {
  path: 'auth',
  element: (
    <Suspense fallback={<SplashScreen />}>
      <Outlet />
    </Suspense>
  ),
  children: [
    {
      path: 'auto-login',
      element: (
        <GuestGuard>
          <AutoLoginPage />
        </GuestGuard>
      ),
    },
    {
      path: 'login',
      element: (
        <GuestGuard>
          <AuthModernLayout>
            <JwtLoginPage />
          </AuthModernLayout>
        </GuestGuard>
      ),
    },
    {
      path: 'register',
      element: (
        <AuthModernLayout>
          <JwtRegisterPage />
        </AuthModernLayout>
      ),
    },
    {
      element: (
        <CompactLayout>
          <Outlet />
        </CompactLayout>
      ),
      children: [
        { path: 'verify', element: <VerifyPage /> },
        { path: 'new-password', element: <NewPasswordPage /> },
        { path: 'forgot-password', element: <ForgotPasswordPage /> },
      ],
    },
  ],
};

export const authRoutes = [
  authJwt
];
