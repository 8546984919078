// @mui
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import { CardProps } from '@mui/material/Card';
import Typography from '@mui/material/Typography';
// assets
import { PlanStarterIcon, PlanPremiumIcon, PlanEnterpriseIcon } from 'src/assets/icons';
// components
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import { endpoints } from 'src/utils/axios';
import { createCheckoutSession } from 'src/api/payments';

// ----------------------------------------------------------------------

type Props = CardProps & {
  card: {
    subscription: string;
    enabled: boolean;
    price: number;
    caption: string;
    labelAction: string;
    lists: string[];
  };
  index: number;
};

export default function PricingCard({ card, sx, ...other }: Props) {
  const { subscription, enabled, price, caption, lists, labelAction } = card;

  const starter = subscription === 'starter';

  const premium = subscription === 'premium';
  
  const enterprise = subscription === 'enterprise';

  const onPlanSelected = async () => {
    createCheckoutSession();
  }

  const renderIcon = (
    <Stack direction="row" alignItems="center" justifyContent="space-between">
      <Box sx={{ width: 48, height: 48 }}>
        {starter && <PlanStarterIcon />}
        {premium && <PlanPremiumIcon />}
        {enterprise && <PlanEnterpriseIcon />}
      </Box>

      {starter && <Label color="info">POPULAR</Label>}
    </Stack>
  );

  const renderSubscription = (
    <Stack spacing={1}>
      <Typography variant="h4" sx={{ textTransform: 'capitalize' }}>
        {subscription}
      </Typography>
      <Typography variant="subtitle2">{caption}</Typography>
    </Stack>
  );

  const renderPrice = (
    <Stack direction="row">
      <Typography variant="h4">$</Typography>

      <Typography variant="h2">{price}</Typography>

      <Typography
        component="span"
        sx={{ alignSelf: 'center', color: 'text.disabled', ml: 1, typography: 'body2' }}
      >
        / mo
      </Typography>
    </Stack>
  );

  const renderList = (
    <Stack spacing={2}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Box component="span" sx={{ typography: 'overline' }}>
          Features
        </Box>
        {/* <Link variant="body2" color="inherit" underline="always">
          All
        </Link> */}
      </Stack>

      {lists.map((item) => (
        <Stack
          key={item}
          spacing={1}
          direction="row"
          alignItems="center"
          sx={{
            typography: 'body2',
          }}
        >
          <Iconify icon="eva:checkmark-fill" width={16} sx={{ mr: 1 }} />
          {item}
        </Stack>
      ))}
    </Stack>
  );

  return (
    <Stack
      spacing={5}
      sx={{
        p: 5,
        borderRadius: 2,
        boxShadow: (theme) => ({
          md: theme.customShadows.card,
        }),
        // boxShadow: (theme) => ({
        //   xs: theme.customShadows.card,
        //   md: 'none',
        // }),
        // ...(starter && {
        //   borderTopRightRadius: { md: 0 },
        //   borderBottomRightRadius: { md: 0 },
        // }),
        // ...((starter || premium) && {
        //   boxShadow: (theme) => ({
        //     xs: theme.customShadows.card,
        //     md: `-40px 40px 80px 0px ${alpha(
        //       theme.palette.mode === 'light' ? theme.palette.grey[500] : theme.palette.common.black,
        //       0.16
        //     )}`,
        //   }),
        // }),
        ...sx,
      }}
      {...other}
    >
      {renderIcon}

      {renderSubscription}

      {renderPrice}

      <Divider sx={{ borderStyle: 'dashed' }} />

      {renderList}

      <Button
        fullWidth
        size="large"
        variant="contained"
        disabled={!enabled}
        // color={starter ? 'primary' : 'inherit'}
        onClick={onPlanSelected}
      >
        {labelAction}
      </Button>
    </Stack>
  );
}
