import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSearchParams } from 'react-router-dom';
import { VerifyView } from 'src/sections/auth';

// ----------------------------------------------------------------------

export default function VerifyPage() {

  const [searchParams] = useSearchParams();
  const email = searchParams.get('email');

  useEffect(() => {
    if (!email) {
      window.document.location = "/";
    }
  }, [email]);

  return (
    <>
      <Helmet>
        <title> Verify</title>
      </Helmet>

      {email && <VerifyView email={email}/>}
    </>
  );
}
