import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { hideScroll } from 'src/theme/css';
import { useMockedUser } from 'src/hooks/use-mocked-user';
import { NavSectionMini } from 'src/components/nav-section';
import { NAV } from '../config-layout';
import { useNavData } from './config-navigation';

// ----------------------------------------------------------------------

export default function NavMini() {
  const { user } = useMockedUser();

  const navData = useNavData();

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: 0,
        width: NAV.W_MINI,
        pt: 11
      }}
    >
      <Stack
        sx={{
          pb: 2,
          height: 1,
          position: 'fixed',
          width: NAV.W_MINI,
          borderRight: (theme) => `dashed 1px ${theme.palette.divider}`,
          ...hideScroll.x,
        }}
      >
        <NavSectionMini
          data={navData}
          config={{
            currentRole: user?.role || 'admin',
          }}
        />
      </Stack>
    </Box>
  );
}
