import { useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import { IChatParticipant, IChatMessage } from 'src/types/chat';
import { Stack } from '@mui/material';
import Lightbox, { useLightBox } from 'src/components/lightbox';
import ChatMessageItem from './chat-message-item';

// ----------------------------------------------------------------------

type Props = {
  messages: IChatMessage[];
  onMessageChanged: (message: IChatMessage) => void,
  onQuestionAnswered: (index: number, answer: string, session_id: string) => void,
  participants: IChatParticipant[];
};

export default function ChatMessageList({ messages = [], onMessageChanged, onQuestionAnswered, participants }: Props) {
  
  const messagesEndRef = useRef<any>(null);
  const [hasUserScrolled, setHasUserScrolled] = useState(false);
  const [prevScrollTop, setPrevScrollTop] = useState(0);

  const slides = messages
    .filter((message) => message.contentType === 'image')
    .map((message) => ({ src: message.body }));
    
  useEffect(() => {
    if (messages?.length > 1 && (messages[messages.length-1].senderId !== '0' || messages[messages.length-1].contentType === "multi_answer_question")) {
      setHasUserScrolled(false);   
    }
  }, [messages, setHasUserScrolled])

  useEffect(
    () => {
      // if(hasUserScrolled) return;
      const scrollHeight = messagesEndRef.current.scrollHeight;
      messagesEndRef.current.scrollTo({top: scrollHeight});
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [messages, hasUserScrolled, messagesEndRef]
  );

  const onScroll = () => {
      if (!messagesEndRef.current || !messages) return;
      // If the user has scroll back
      if(prevScrollTop > messagesEndRef.current.scrollTop) {
        setHasUserScrolled(true);
      }
      setPrevScrollTop(messagesEndRef.current.scrollTop)
  }

  const lightbox = useLightBox(slides);

  return (
    <Stack direction="column" gap={5} ref={messagesEndRef} onScroll={() => onScroll()} sx={{
      overflow: "scroll",
      float: "right",
      pt: '200px',
      px: 3,
      height: '100vh',
    }}>
      {messages.map((message, index) => (
        <Box key={index}>
          <ChatMessageItem
            message={message}
            onMessageChanged={onMessageChanged}
            onQuestionAnswered={onQuestionAnswered}
            participants={participants}
            onOpenLightbox={() => lightbox.onOpen(message.body)}
          />
        </Box>
      ))}
      <Lightbox
        index={lightbox.selected}
        slides={slides}
        open={lightbox.open}
        close={lightbox.onClose}
      />
    </Stack>
  );
}
