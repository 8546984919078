import { useRef, useState, useCallback, useMemo, useContext, useEffect } from 'react';
import InputBase from '@mui/material/InputBase';
import { useNavigate } from 'react-router';
import mixpanel from 'mixpanel-browser';
import { paths } from 'src/routes/paths';
import Iconify from 'src/components/iconify';
import { IChatMessage } from 'src/types/chat';
import { Card, Divider, IconButton, Stack } from '@mui/material';
import { useAuthContext } from 'src/auth/hooks';
import './chat.css';


const iceBreakers = [
  { title: "@chat", category: "tools", link: paths.chat.root},
  { title: "@kpis", category: "tools", link: paths.root},
  { title: "@planning", category: "tools", link: paths.planning.root},
  { title: "@settings", category: "tools", link: paths.settings.root},
  { title: "I anticipate expenses that will impact my Runway", category: "Ice Breakers"},
  { title: "Compare my P&L from the past two months", category: "Ice Breakers"},
  { title: "Show me my top five expenses from last month", category: "Ice Breakers"},
  { title: "Display my vendors' balances", category: "Ice Breakers"},
]

type Props = {
  onMessageSend: (message: IChatMessage) => void;
  onShowIceBreakers?: () => void;
  disabled?: boolean;
  onStop: () => void;
  orgId: string;
  placeHolder?: string,
  showIceBreakers?: boolean;
  showExpand?: boolean;
};


export default function ChatMessageInput({
  onMessageSend,
  onShowIceBreakers,
  disabled,
  onStop,
  orgId,
  placeHolder = "Type your message here",
  showIceBreakers = true,
  showExpand = true
}: Props) {

  const fileRef = useRef<HTMLInputElement>(null);
  const [message, setMessage] = useState('');
  const [isDisabled, setIsDisabled] = useState(disabled);
  const {user} = useAuthContext();
  const navigate = useNavigate();

  useEffect(() => {
    setIsDisabled(disabled);
  }, [disabled])

  const uint8ArrayToBase64 = (buffer: Uint8Array) => {
    let binary = '';
    const len = buffer.byteLength;
    for (let i = 0; i < len; i+=1) {
        binary += String.fromCharCode(buffer[i]);
    }
    return window.btoa(binary);
  }

  const handleFileChange = () => {
    if (fileRef.current?.files && fileRef.current.files.length > 0) {
      const file: File = fileRef.current.files[0];
      
      const reader = new FileReader();

      reader.onload = (event) => {
        if (event.target && event.target.result) {
            const base64File = uint8ArrayToBase64(new Uint8Array(event.target.result as ArrayBuffer));
            const command = {
              body: base64File,
              contentType: "upload_invoice",
              mimeType: file.type,
              orgId
            }
            onMessageSend(command);
        } else {
            console.error('Failed to read file content.');
        }
      }

      reader.readAsArrayBuffer(file);
    }
  }

  const messageData = useMemo(
    () => ({
      body: message,
      contentType: "text"
    }),
    [message]
  );

  // useEffect(() => {
  //   if(!selectedQuestion?.text) return;
  //   const question = {
  //     body: selectedQuestion.text,
  //     contentType: "text",
  //     orgId
  //   };
  //   const sendMessage = async () => {
  //     await onMessageSend(question);
  //   }
  //   // Track login event
  //   mixpanel.track("system_message_sent", {
  //     "user_id": user?.id,
  //   });
  //   sendMessage();
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [selectedQuestion]);

  const handleAttach = useCallback(() => {
    if (fileRef.current) {
      fileRef.current.click();
    }
  }, []);

  const handleStop = useCallback(() => {
    if(onStop) onStop();
  }, [onStop]);

  const handleChangeMessage = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setMessage(event.target.value);
  }, []);

  const sendMessage = useCallback(
    async () => {
      onMessageSend({...messageData, body: messageData.body.replace(/\n/g, '<BR>')});
      setMessage('');
    },
    [onMessageSend, messageData]
  );

  const handleSendMessage = useCallback(
    async (event: React.KeyboardEvent<HTMLInputElement>) => {
      try {
        if (event.key === 'Enter' && !isDisabled && !event.shiftKey) {
          sendMessage();

          mixpanel.track("user_send_custom_message", {
            "user_id": user?.id,
            "user_name": `${user?.first_name} ${user?.last_name}`, 
          });
        }
      } catch (error) {
        console.error(error);
      }
    },
    [sendMessage, isDisabled, user]
  );

  return (
    <Card sx={{
      width: 800,
      mx: 'auto',
      my: 1,
      height: 70,
      borderRadius: 10,
      border: '2px solid #a19df5'
    }}>
      <InputBase
        multiline
        disabled={orgId==='0'}
        value={message}
        onKeyDown={(event) => {if (event.key === 'Enter' && !event.shiftKey) {event.stopPropagation();event.preventDefault();}}}
        onKeyUp={handleSendMessage}
        onChange={handleChangeMessage}
        placeholder={orgId==='0' ? "Please select organization from the top right menu" : placeHolder}
        startAdornment={
          <>
            <Iconify icon="eva:message-square-outline" sx={{mr: 1}}/>
            <Divider orientation='vertical'/>&nbsp;&nbsp;
          </>
        }
        endAdornment={
          <Stack direction="row" sx={{ flexShrink: 0 }}>
            {showIceBreakers && <IconButton onClick={() => {
              if(onShowIceBreakers) {
                onShowIceBreakers();
              }
            }}>
              <Iconify icon="iconamoon:question-mark-circle-light" />
            </IconButton>}
            {showExpand && <IconButton onClick={() => {
              mixpanel.track("open_ice_breakers_clicked", {
                "user_id": user?.id,
                "user_name": `${user?.first_name} ${user?.last_name}`, 
              });
              navigate(paths.chat.root);
            }}>
              <Iconify icon="pepicons-print:expand" />
            </IconButton>}
          </Stack>
        }
        sx={{
          pt: 2,
          px: 3,
          width: '100%',
          height: 56,
          flexShrink: 0,
        }}
      />
      <input type="file" onChange={handleFileChange} ref={fileRef} style={{ display: 'none' }} />
    </Card>
  )
}
