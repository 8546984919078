import { alpha } from '@mui/material/styles';
import tinycolor from 'tinycolor2';

interface ColorScheme {
  name: string;
  lighter: string;
  light: string;
  main: string;
  dark: string;
  darker: string;
  contrastText: string;
}

export function presets(primaryColor: string, secondaryColor: string) {
  const primary = generateColorScheme(primaryColor, "primary");
  const secondary = generateColorScheme(secondaryColor, "secondary");

  const theme = {
    palette: {
      primary,
      secondary,
    },
    customShadows: {
      primary: `0 8px 16px 0 ${alpha(`${primary?.main}`, 0.24)}`,
    },
  };

  return theme;
}

export function generateColorScheme(color: string, name: string): ColorScheme {
  return {
    name,
    lighter: tinycolor(color).lighten(12).toHexString(),
    light: tinycolor(color).lighten(6).toHexString(),
    main: tinycolor(color).toHexString(),
    dark: tinycolor(color).darken(6).toHexString(),
    darker: tinycolor(color).darken(12).toHexString(),
    contrastText: tinycolor(color).isDark() ? '#FFFFFF' : '#000000',
  };
}
