export const getCurrencySymbol = (currencyCode: string): string => {
  const symbolDict: { [key: string]: string } = {
      'USD': '$',
      'EUR': '€',
      'JPY': '¥',
      'GBP': '£',
      'AUD': 'A$',
      'CAD': 'C$',
      'CHF': 'Fr',
      'CNY': '¥',
      'SEK': 'kr',
      'NZD': 'NZ$',
      'MXN': '$',
      'SGD': 'S$',
      'HKD': 'HK$',
      'NOK': 'kr',
      'KRW': '₩',
      'TRY': '₺',
      'RUB': '₽',
      'INR': '₹',
      'BRL': 'R$',
      'ZAR': 'R',
      'DKK': 'kr',
      'PLN': 'zł',
      'THB': '฿',
      'IDR': 'Rp',
      'HUF': 'Ft',
      'CZK': 'Kč',
      'ILS': '₪',
      'CLP': '$',
      'PHP': '₱',
      'AED': 'د.إ',
      'COP': '$',
      'SAR': '﷼',
      'MYR': 'RM',
      'RON': 'lei',
      // add more currencies as needed
  }
  
  return symbolDict[currencyCode] || '';
}