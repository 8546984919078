import { useEffect, useReducer, useCallback, useMemo, useContext } from 'react';
import mixpanel from 'mixpanel-browser';
import axios, { endpoints } from 'src/utils/axios';
import { useGoogleLogin } from '@react-oauth/google';
import { AppContext } from 'src/context/app-context';
import { AuthContext } from './auth-context';
import { setSession } from './utils';
import { ActionMapType, AuthStateType, AuthUserType } from '../../types';

enum Types {
  INITIAL = 'INITIAL',
  LOGIN = 'LOGIN',
  REGISTER = 'REGISTER',
  LOGOUT = 'LOGOUT',
}

type Payload = {
  [Types.INITIAL]: {
    user: AuthUserType;
  };
  [Types.LOGIN]: {
    user: AuthUserType;
  };
  [Types.REGISTER]: undefined;
  [Types.LOGOUT]: undefined;
};

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

const initialState: AuthStateType = {
  user: null,
  loading: true,
  initialized: false,
};

const reducer = (state: AuthStateType, action: ActionsType) => {
  if (action.type === Types.INITIAL) {
    return {
      loading: false,
      user: action.payload.user,
      initialized: true,
    };
  }
  if (action.type === Types.LOGIN) {
    return {
      ...state,
      initialized: false,
    };
  }
  if (action.type === Types.REGISTER) {
    return {
      ...state,
      initialized: false,
    };
  }
  if (action.type === Types.LOGOUT) {
    return {
      ...state,
      user: null,
    };
  }
  return state;
};

type Props = {
  children: React.ReactNode;
};

const initializePendo = (user: any) => {
  // This function creates visitors and accounts in Pendo
  // You will need to replace <visitor-id-goes-here> and <account-id-goes-here> with values you use in your app
  // Please use Strings, Numbers, or Bools for value types.
  (window as any).pendo.initialize({
    visitor: {
        id: user.id, // Required if user is logged in
        email: user.email,           // Recommended if using Pendo Feedback, or NPS Email
        full_name: `${user.first_name} ${user.last_name}`   // Recommended if using Pendo Feedback
        // role:         // Optional

        // You can add any additional visitor level key-values here,
        // as long as it's not one of the above reserved names.
    },

    account: {
        // id:              orgId // Highly recommended, required if using Pendo Feedback
        // name:         // Optional
        // is_paying:    // Recommended if using Pendo Feedback
        // monthly_value:// Recommended if using Pendo Feedback
        // planLevel:    // Optional
        // planPrice:    // Optional
        // creationDate: // Optional

        // You can add any additional account level key-values here,
        // as long as it's not one of the above reserved names.
    }
  });
}

export function AuthProvider({ children }: Props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { setSelectedOrganizationId, selectedOrganizationId } = useContext(AppContext);
  
  useEffect(() => {
    console.log(selectedOrganizationId);
  }, [selectedOrganizationId])

  const initialize = useCallback(async () => {
    try {
      const response = await axios.get(endpoints.users.me);
      if (response.status !== 200) throw new Error("Cannot fetch user");

      const user = await response.data;

      
      user.displayName = `${user?.first_name} ${user?.last_name}`;
      
      // Track login event
      mixpanel.track((user.status === "ANONYMOUS" ? "init_anonymous" : "init"), {
        "user_id": user.id,
        "user_name": `${user?.first_name} ${user?.last_name}`,
      });
      
      initializePendo(user);
      
      dispatch({
        type: Types.INITIAL,
        payload: {
          user,
        },
      });

      setSelectedOrganizationId(user.organizations[0].id);
    } catch (error) {
      console.error(error);
      dispatch({
        type: Types.INITIAL,
        payload: {
          user: null,
        },
      });
    }
  }, [setSelectedOrganizationId]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const loginWithXero = useCallback(async () => {
    if (window.location.origin.includes("localhost")) {
      window.location.replace("http://localhost:5000/api/v1/login/xero");
    } else {
      window.location.replace("/api/v1/login/xero");
    }
  }, []);

  const loginWithQB = useCallback(async () => {
    if (window.location.origin.includes("localhost")) {
      window.location.replace("http://localhost:5000/api/v1/login/qb");
    } else {
      window.location.replace("/api/v1/login/qb");
    }
  }, []);

  // LOGIN With GOOGLE
  const loginWithGoogle = useGoogleLogin({
    flow: "auth-code",
    scope: "https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile",
    onSuccess: (codeResponse: any) => {
      (async function () {
        const data = {
          code: codeResponse.code
        }
        const response = await axios.post(endpoints.auth.login_with_google, data);
        if(response.status === 200) {
          initialize();
        } else if(response.status === 201) {
          const r = await response.data;
          const user = r.user
          dispatch({
            type: Types.REGISTER,
          })
        } else {
          throw Error("Login failed: incorrect Email or Password")
        }
      })();
    },
    onError: (error: any) => console.log('Login Failed:', error)
  });

  // // LOGIN
  // const login = useCallback(async (email: string, password: string) => {
  //   const data = {
  //     email,
  //     password,
  //   };

  //   const response = await axios.post(endpoints.auth.login, data);

  //   const { accessToken, user } = response.data;

  //   setSession(accessToken);

  //   dispatch({
  //     type: Types.LOGIN,
  //     payload: {
  //       user,
  //     },
  //   });
  // }, []);


  // LOGIN
  const login = useCallback(async (email: string, password: string) => {
    dispatch({
      type: Types.LOGIN,
      payload: {
        user: null,
      },
    });
    const data = {
      email,
      password,
    };
    
    const response = await axios.post(endpoints.auth.login, data);

    if(response.status === 200) {
      
      // Track login event
      mixpanel.track("login_success", {
        "email": email
      });
      
      initialize();
    } else {
      throw Error("Login failed: incorrect Email or Password")
    }
  }, [initialize]);


  // ANONYMOUS LOGIN
  const anonymousLogin = useCallback(async () => {
    dispatch({
      type: Types.LOGIN,
      payload: {
        user: null,
      },
    });
    const data = {};
    
    const response = await axios.post(endpoints.auth.anonymousLogin, data);

    if(response.status === 200) {
      // Track login event
      mixpanel.track("login_success_anonymous", {
        
      });
      initialize();
    } else {
      throw Error("Login failed: incorrect Email or Password")
    }
  }, [initialize]);


  // Reload
  const reload = useCallback(async () => {
    initialize();
  }, [initialize]);

  // REGISTER
  const register = useCallback(
    async (email: string, password: string, orgName: string, firstName: string, lastName: string) => {
      const data = {
        email,
        password,
        org_name: orgName,
        first_name: firstName,
        last_name: lastName,
      };

      await axios.post(endpoints.auth.register, data);

      dispatch({
        type: Types.REGISTER,
      });
    },
    []
  );

  // // LOGOUT
  // const logout = useCallback(async () => {
  //   setSession(null);
  //   dispatch({
  //     type: Types.LOGOUT,
  //   });
  // }, []);

  // LOGOUT
  const logout = useCallback(async () => {
    setSession(null);
    dispatch({
      type: Types.LOGOUT,
    });
    const response = await axios.delete(endpoints.auth.logout);
  }, []);


  // ----------------------------------------------------------------------

  const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated';

  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      method: 'jwt',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      initialized: state.initialized,
      login,
      anonymousLogin,
      loginWithGoogle,
      loginWithXero,
      loginWithQB,
      register,
      logout,
      reload
    }),
    [login, anonymousLogin, logout, register, loginWithGoogle, loginWithXero, loginWithQB, state.user, state.initialized, status, reload]
  );
  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}
