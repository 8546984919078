import { useMemo } from 'react';
import useSWR, { mutate } from 'swr';
import axios, { endpoints, fetcher } from 'src/utils/axios';
import {
  IOrganization,
} from 'src/types/organization';

// ----------------------------------------------------------------------

const options = {
  revalidateIfStale: false,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
};


// ----------------------------------------------------------------------

export function useGetOrganizations() {
  
  const URL = `${endpoints.organizations.root}`;

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher, options);

  const memoizedValue = useMemo(
    () => ({
      organizations: data?.conversation as IOrganization,
      organizationsLoading: isLoading,
      organizationsError: error,
      organizationsValidating: isValidating,
    }),
    [data?.conversation, error, isLoading, isValidating]
  );

  return memoizedValue;
}

export async function updateOrganization(organization: any): Promise<void> {

  if(!organization.id) return Promise.resolve();
  const URL = `${endpoints.organizations.root}/${organization.id}`;
  return axios.put(URL, organization);
}

export function useGetMainKPIs(orgId: string) {

  const URL = orgId ? `${endpoints.organizations.root}/${orgId}/main_kpis` : null;
  const fetcherFunction = URL ? fetcher : () => Promise.resolve({ data: null, error: null });
  const { data, isLoading, error, isValidating } = useSWR(URL, fetcherFunction, options);

  const memoizedValue = useMemo(
    () => ({
      kpis: data?.kpis,
      kpisLoading: isLoading,
      kpisError: error,
      kpisValidating: isValidating,
      revalidate: () => mutate(URL),
    }),
    [data?.kpis, error, isLoading, isValidating, URL]
  );

  return memoizedValue;
}

export function useGetAssumptions(orgId: string) {

  const URL = orgId ? `${endpoints.organizations.root}/${orgId}/assumptions` : null;
  const fetcherFunction = URL ? fetcher : () => Promise.resolve({ data: null, error: null });
  const { data, isLoading, error, isValidating } = useSWR(URL, fetcherFunction, options);

  const memoizedValue = useMemo(
    () => ({
      assumptions: data?.assumptions,
      assumptionsLoading: isLoading,
      assumptionsError: error,
      assumptionsValidating: isValidating,
      revalidate: () => mutate(URL),
    }),
    [data?.assumptions, error, isLoading, isValidating, URL]
  );

  return memoizedValue;
}

export async function updateAssumption(assumption: any): Promise<void> {
  if(!assumption.id) return Promise.resolve();
  const URL = `${endpoints.organizations.root}/assumptions/${assumption.id}`;
  return axios.put(URL, assumption);
}

export async function deleteAssumption(assumptionId: string): Promise<void> {
  if (!assumptionId) return Promise.resolve();
  const DELETE_ASSUMPTIONS_URL = `${endpoints.organizations.root}/assumptions/${assumptionId}`;
  return axios.delete(DELETE_ASSUMPTIONS_URL);
}

export function useGetRevenueProjections(orgId: string) {

  const URL = orgId ? `${endpoints.organizations.root}/${orgId}/revenue_projections` : null;
  const fetcherFunction = URL ? fetcher : () => Promise.resolve({ data: null, error: null });
  const { data, isLoading, error, isValidating } = useSWR(URL, fetcherFunction, options);

  const memoizedValue = useMemo(
    () => ({
      revenueProjections: data?.revenue_projections,
      revenueProjectionsLoading: isLoading,
      revenueProjectionsError: error,
      revenueProjectionsValidating: isValidating,
      revalidate: () => mutate(URL),
    }),
    [data?.revenue_projections, error, isLoading, isValidating, URL]
  );

  return memoizedValue;
}

export async function updateRevenueProjection(revenueProjection: any): Promise<void> {
  if(!revenueProjection.id) return Promise.resolve();
  const URL = `${endpoints.organizations.root}/revenue_projections/${revenueProjection.id}`;
  return axios.put(URL, revenueProjection);
}

export async function deleteRevenueProjection(revenueProjectionId: string): Promise<void> {
  if (!revenueProjectionId) return Promise.resolve();
  const DELETE_REVENUE_PROJECTION_URL = `${endpoints.organizations.root}/revenue_projections/${revenueProjectionId}`;
  return axios.delete(DELETE_REVENUE_PROJECTION_URL);
}

export function useGetOrgKPIs(orgId: string) {

  const URL = orgId ? `${endpoints.organizations.root}/${orgId}/org_kpis` : null;
  const fetcherFunction = URL ? fetcher : () => Promise.resolve({ data: null, error: null });
  const { data, isLoading, error, isValidating } = useSWR(URL, fetcherFunction, options);

  const memoizedValue = useMemo(
    () => ({
      kpis: data?.kpis,
      kpisLoading: isLoading,
      kpisError: error,
      kpisValidating: isValidating,
      revalidate: () => mutate(URL, true),
    }),
    [data?.kpis, error, isLoading, isValidating, URL]
  );

  return memoizedValue;
}

export function useGetOrgKPI(orgId: string, kpiName: string) {

  const URL = orgId && kpiName ? `${endpoints.organizations.root}/${orgId}/kpi/${kpiName}` : null;
  const fetcherFunction = URL ? fetcher : () => Promise.resolve({ data: null, error: null });
  const { data, isLoading, error, isValidating } = useSWR(URL, fetcherFunction, options);

  const memoizedValue = useMemo(
    () => ({
      kpi: data?.kpi,
      kpiLoading: isLoading,
      kpiError: error,
      kpiValidating: isValidating,
      revalidate: () => mutate(URL, true),
    }),
    [data?.kpi, error, isLoading, isValidating, URL]
  );

  return memoizedValue;
}

export function useGetAllKPIs() {

  const URL = `${endpoints.organizations.root}/all_kpis`;
  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher, options);
  const memoizedValue = useMemo(
    () => ({
      kpis: data?.kpis,
      kpisLoading: isLoading,
      kpisError: error,
      kpisValidating: isValidating,
      revalidate: () => mutate(URL, true),
    }),
    [data?.kpis, error, isLoading, isValidating, URL]
  );

  return memoizedValue;
}

export function useGetHealthScore(orgId: string) {

  const URL = orgId ?`${endpoints.organizations.root}/${orgId}/health_score` : null;
  const fetcherFunction = URL ? fetcher : () => Promise.resolve({ data: null, error: null });
  const { data, isLoading, error, isValidating } = useSWR(URL, fetcherFunction, options);

  const memoizedValue = useMemo(
    () => ({
      healthScore: data?.score,
      healthScoreLoading: isLoading,
      healthScoreError: error,
      healthScoreValidating: isValidating,
    }),
    [data?.score, error, isLoading, isValidating]
  );

  return memoizedValue;
}

export async function removeKPI(orgId: string, kpiName: string): Promise<void> {

  if(!orgId || !kpiName) return Promise.resolve();
  const REMOVE_URL = endpoints.organizations.remove_kpi(`${orgId}`, kpiName);

  return axios.put(REMOVE_URL);
}

export async function setKPIs(orgId: string, kpiNames: string[]): Promise<void> {

  if(!orgId || !kpiNames) return Promise.resolve();
  const SET_KPIS_URL = endpoints.organizations.set_kpis(`${orgId}`);

  return axios.post(SET_KPIS_URL, kpiNames);
}

export async function disconnectOrg(orgId: string): Promise<void> {

  if(!orgId) return Promise.resolve();
  const DISCONNECT_URL = endpoints.organizations.disconnect_org(`${orgId}`);

  return axios.delete(DISCONNECT_URL);
}

export async function downloadFinancialReport(orgId: string): Promise<void> {
  try {
    if(!orgId) return await Promise.resolve();
    const DDOWNLOAD_URL = endpoints.organizations.download_financial_report(`${orgId}`);
    const response = await axios.get(DDOWNLOAD_URL, {
      responseType: 'blob', // Important
    });

    // Create a new Blob object using the response data of the file
    const file = new Blob([response.data], { type: 'application/pdf' });

    // Create a URL for the file
    const fileURL = URL.createObjectURL(file);

    // Create a temporary tag to trigger download
    const fileLink = document.createElement('a');
    fileLink.href = fileURL;
    fileLink.setAttribute('download', 'financial_report.pdf'); // or any other name
    document.body.appendChild(fileLink);
    
    fileLink.click();

    // Clean up and remove the link
    fileLink?.parentNode?.removeChild(fileLink);
    URL.revokeObjectURL(fileURL); // Free up memory
    return await Promise.resolve();
  } catch (error) {
    console.error('Error downloading the file', error);
  }
  return Promise.resolve();
}

