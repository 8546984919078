import { useCallback, useContext, useEffect, useState } from 'react';
import { m } from 'framer-motion';
import mixpanel from 'mixpanel-browser';
import { disconnectOrg } from 'src/api/organizations';
import LoadingButton from '@mui/lab/LoadingButton';
import { Badge, Card, Box, Button, CardHeader, Stack, Typography, CardContent } from '@mui/material';
import { varHover } from 'src/components/animate';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { useAuthContext } from 'src/auth/hooks';
import { IOrganization } from 'src/types/organization';
import { paths } from 'src/routes/paths';
import { useLocation, useNavigate } from 'react-router';
import { useLocales } from 'src/locales';
import { useGetUnreadMessages } from 'src/api/chat';
import { AppContext } from 'src/context/app-context';
import Iconify from 'src/components/iconify/iconify';
import { ConfirmDialog } from 'src/components/custom-dialog';
import { useBoolean } from 'src/hooks/use-boolean';

// ----------------------------------------------------------------------

interface IOrgMenuItem {
  id: string,
  label: string;
  path: string;
  unreadMessages?: number;
}

interface IOrganizationsSelectorProps {
  renderAsPopover?: boolean,
}
// ----------------------------------------------------------------------

export default function OrganizationsSelector({renderAsPopover} : IOrganizationsSelectorProps) {

  const { setSelectedOrganizationId, selectedOrganizationId } = useContext(AppContext);

  const [ isDisconnecting, setIsDisconnecting ] = useState(false);

  const confirm = useBoolean();

  const location = useLocation();

  const popover = usePopover();

  const navigate = useNavigate();

  const unreadMessages = useGetUnreadMessages();

  const [selectedOrganization, setSelectedOrganization] = useState<IOrgMenuItem>();
  
  const { t } = useLocales();  

  const { user, reload: reloadUser } = useAuthContext();

  const [ unreadMessagesData, setUnreadMessagesData] = useState<{ [key: string]: number }>();

  const [ totalUnreadMessages, setTotalUnreadMessages ] = useState(0);

  const [ orgIdToDelete, setOrgIdToDelete] = useState<string>();
   
  const [ isShowAddCompanies, setIsShowAddCompanies ] = useState(true);

  useEffect(() => {
    setOrganizations(user?.organizations.map((org: IOrganization) => {
      let path = `${paths.root}`;
      // if (location.pathname.includes(paths.income.root)) {
      //   path = `${paths.income.root}`;
      // }
      // if (location.pathname.includes(paths.expenses.root)) {
      //   path = `${paths.expenses.root}`;
      // }
      // if (location.pathname.includes(paths.unitEconomics.root)) {
      //   path = `${paths.unitEconomics.root}`;
      // }
      if (location.pathname.includes(paths.settings.root)) {
        path = `${paths.settings.root}`;
      }
      return {label: org.name, path, id: org.id};
    }));
  }, [user?.organizations, user, location.pathname]);

  const handleOrgDelete = async () => {
    if (!orgIdToDelete) return;
    setIsDisconnecting(true);
    try {
      await disconnectOrg(orgIdToDelete).then(() => {
        reloadUser();
        setIsDisconnecting(false);
        navigate(paths.root);
        confirm.onFalse()
        // window.location.reload()
      });
    } catch (error) {
      setIsDisconnecting(false);
      console.error(error);
      confirm.onFalse()
    }
  };
  
  const [organizations, setOrganizations] = useState<IOrgMenuItem[]>();

  const handleXeroConnect = useCallback(async () => {
    if (window.location.origin.includes("localhost")) {
      window.location.replace("http://localhost:5000/api/v1/login/xero");
    } else {
      window.location.replace("/api/v1/login/xero");
    }
  }, []);

  const handleQBConnect = useCallback(async () => {
    if (window.location.origin.includes("localhost")) {
      window.location.replace(`http://localhost:5000/api/v1/organizations/${selectedOrganizationId}/qb/connect`);
    } else {
      window.location.replace(`/api/v1/organizations/${selectedOrganizationId}/qb/connect`);
    }
  }, [selectedOrganizationId]);

  useEffect(() => {
    const selectedOrg = organizations?.find((org) => `${org.id}` === selectedOrganizationId);
    setSelectedOrganization(selectedOrg);
  }, [selectedOrganizationId, organizations, setSelectedOrganization])

  useEffect(() => {
    if(unreadMessages?.data) {
      setUnreadMessagesData(unreadMessages.data);
    }
  }, [unreadMessages?.data])

  const handleChangeOrganization = useCallback((organization: IOrgMenuItem) => {
    navigate(organization.path);
    setUnreadMessagesData(prev => ({...prev, [organization.id]: 0}));
    setSelectedOrganizationId(organization.id)
    popover.onClose();

    // Track login event
    mixpanel.track("select_organization", {
      "user_id": user?.id,
    });

  }, [popover, navigate, setSelectedOrganizationId, user?.id]);

  useEffect(() => {
    if(!unreadMessagesData) return;
    setTotalUnreadMessages(Object.values(unreadMessagesData as {[key: string]: number}).reduce((acc:number, num:number) => acc + num, 0));
    setOrganizations(prevOrganizations => prevOrganizations?.map((org) => ({ ...org, unreadMessages: unreadMessagesData[org.id]})));
  }, [unreadMessagesData])

  const renderContent = () => (
    <>
      {organizations && organizations.map((option: IOrgMenuItem, index) => (
        <Stack direction="row" justifyContent="space-between" key={index} sx={{mb:1}}>
          <Badge key={index} sx={{width: "90%", '.MuiBadge-badge': {top: 17, right: 11}}} anchorOrigin={{ vertical: 'top', horizontal: 'right', }} badgeContent={option.unreadMessages} color="success">
            <Button
              fullWidth
              key={option.label}
              onClick={() => handleChangeOrganization(option)}
            >
              <Typography sx={{width: "100%"}} textAlign="left" variant="caption">
                {option.label}
              </Typography>
            </Button>
          </Badge>
          <Iconify color="red" icon="solar:trash-bin-trash-outline" 
              onClick={() => {setOrgIdToDelete(option.id); confirm.onTrue()}}
              sx={{mt:1, cursor:"pointer"}}/>
        </Stack>
      ))}

      <Box sx={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "start",
          borderTop: (theme) => `solid 1px ${theme.palette.divider}`, p: 1, gap: 1}}>
        {/* <Button onClick={() => setIsShowAddCompanies(true)}>
          <Typography fontSize={11} fontWeight="bold">
            Connect more companies
          </Typography>
        </Button> */}
        <Typography variant='button'>
          Connect companies
        </Typography>
        {isShowAddCompanies && (
          <Box sx={{
            display: 'flex', alignItems: 'center', gap: 1
          }}>
            <Button
              id="qb_button"
              color="error"
              variant="text"
              onClick={handleQBConnect}
              sx={{
                backgroundColor: 'white',
                padding: 0,
                minWidth: 160, // Set a fixed width
                height: 40, // Set a fixed height
                '& img': {
                  width: '100%', // Ensure the image fills the button width
                  height: 40 // Adjust height to maintain aspect ratio
                }
              }}
            >
              <img
                src="/assets/icons/auth/C2QB_green_btn_med_default.svg"
                alt="Connect to QuickBooks"
              />
            </Button>
              
            {/* <Button
              id="xero_button"
              color="error"
              variant="text"
              onClick={handleXeroConnect}
              sx={{
                backgroundColor: 'white',
                padding: 0,
                minWidth: 160, // Match width with the first button
                height: 40, // Match height with the first button
                '& img': {
                  width: '100%', // Ensure the image fills the button width
                  height: 40 // Adjust height to maintain aspect ratio
                }
              }}
            >
              <img
                src="/assets/icons/auth/xero-connect-blue.svg"
                alt="Connect to Xero"
              />
            </Button> */}
          </Box>
        )}
      </Box>
    </>
  );

  return (
    
    <Badge anchorOrigin={{ vertical: 'top', horizontal: 'left', }} badgeContent={totalUnreadMessages} color="success">
      {renderAsPopover ? (
        <>
          <Button
            variant="outlined"
            component={m.button}
            whileTap="tap"
            whileHover="hover"
            variants={varHover(1.05)}
            onClick={popover.onOpen}
            sx={{
              width: 200,
              height: 40,
              ...(popover.open && {
                bgcolor: 'action.selected',
              }),
            }}
          >
            {selectedOrganization?.label || "Select Organization"}
          </Button>
          <CustomPopover open={popover.open} onClose={popover.onClose} sx={{ flexDirection: 'column', display: 'flex' }}>
            {renderContent()}
          </CustomPopover>
        </>
      ) : (
        <Card>
          <CardHeader title="Switch or Connect Organizations"/>
          <CardContent>
            {renderContent()}
          </CardContent>
        </Card>
      )}
        
      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Disconnection Approval"
        content="Are you sure you want to disconnect this company?"
        action={
          <LoadingButton variant="contained" color="error" loading={isDisconnecting}
            onClick={() => handleOrgDelete()}>
            Disconnect
          </LoadingButton>
        }
      />
    </Badge>

)}