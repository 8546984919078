import { useMemo } from 'react';
import useSWR, { mutate } from 'swr';
// utils
import axios, { endpoints, fetcher } from 'src/utils/axios';
// types
import {
  IChatParticipant,
  IChatConversation,
  IChatSampleQuestion,
  IScheduledQuestion,
  IFavoriteQuestion
} from 'src/types/chat';

// ----------------------------------------------------------------------

const options = {
  revalidateIfStale: false,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
};

export function useGetContacts() {
  const URL = `${endpoints.chat.contacts}`;

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher, options);

  const memoizedValue = useMemo(
    () => ({
      contacts: (data?.contacts as IChatParticipant[]) || [],
      contactsLoading: isLoading,
      contactsError: error,
      contactsValidating: isValidating,
      contactsEmpty: !isLoading && !data?.contacts.length,
    }),
    [data?.contacts, error, isLoading, isValidating]
  );

  return memoizedValue;
}

// ----------------------------------------------------------------------

export function useGetUnreadMessages() {
  const URL = `${endpoints.chat.unreadMessages}`;

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher, options);

  const memoizedValue = useMemo(() => 
    ({
      data,
      unreadMessagesLoading: isLoading,
      unreadMessagesError: error,
      unreadMessagesValidating: isValidating,
    })
  , [data, error, isLoading, isValidating]);

  return memoizedValue;
}

// ----------------------------------------------------------------------
export function useGetConversation(userId: string, orgId: string) {

  const URL = orgId && orgId !== "0" && userId ? `${endpoints.chat.root}/conversation/user/${userId}/org/${orgId}` : null;
  const fetcherFunction = URL ? fetcher : () => Promise.resolve({ data: null, error: null });
  const { data, isLoading, error, isValidating } = useSWR(URL, fetcherFunction, options);
  
  const memoizedValue = useMemo(
    () => ({
      conversation: data?.conversation as IChatConversation,
      conversationLoading: isLoading,
      conversationError: error,
      conversationValidating: isValidating,
      revalidate: () => mutate(URL),
    }),
    [data?.conversation, error, isLoading, isValidating, URL]
  );

  return memoizedValue;
}

export function useGetSampleQuestions() {

  const URL = endpoints.chat.sampleQuestions;

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher, options);
  

  const memoizedValue = useMemo(
    () => ({
      questions: data as IChatSampleQuestion[],
      questionsLoading: isLoading,
      questionsError: error,
      questionsValidating: isValidating,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}

export function useGetScheduledQuestions(orgId: string) {

  const URL = orgId && orgId !== "0" ? `${endpoints.chat.scheduleQuestion}?org_id=${orgId}` : null;

  const fetcherFunction = URL ? fetcher : () => Promise.resolve({ data: null, error: null });

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcherFunction, options);
  
  const memoizedValue = useMemo(
    () => ({
      questions: data as IScheduledQuestion[],
      questionsLoading: isLoading,
      questionsError: error,
      questionsValidating: isValidating,
      revalidate: () => mutate(URL),
    }),
    [data, error, isLoading, isValidating, URL]
  );

  return memoizedValue;
}

export function useGetFavoriteQuestions() {

  const URL = endpoints.chat.favoriteQuestion;

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher, options);
  
  const memoizedValue = useMemo(
    () => ({
      questions: data as IFavoriteQuestion[],
      questionsLoading: isLoading,
      questionsError: error,
      questionsValidating: isValidating,
      revalidate: () => mutate(URL),
    }),
    [data, error, isLoading, isValidating, URL]
  );

  return memoizedValue;
}

export async function deleteFavorite(questionId: string): Promise<void> {

  if (!questionId) return Promise.resolve();
  const DELETE_FAVORITE_URL = `${endpoints.chat.favoriteQuestion}/${questionId}`;
  return axios.delete(DELETE_FAVORITE_URL);
}

export async function deleteScheduled(questionId: string): Promise<void> {

  if (!questionId) return Promise.resolve();
  const DELETE_FAVORITE_URL = `${endpoints.chat.scheduleQuestion}/${questionId}`;
  return axios.delete(DELETE_FAVORITE_URL);
}


