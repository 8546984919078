import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import './styles.css';

type TypingIndicatorProps = {
  size?: number;
  color?: string;
  sx?: any;
};

export default function TypingIndicator({
  size = 8,
  color = '#9b81d2',
  sx = {}
}: TypingIndicatorProps) {
  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setShowMessage((prev) => !prev);
    }, 4000);

    return () => clearInterval(interval);
  }, []);

  const animationStyles = {
    animation: 'typingAnimation 1.2s infinite',
    display: 'inline-block',
    width: size,
    height: size,
    borderRadius: '50%',
    backgroundColor: color,
    marginLeft: size / 4,
    opacity: 0
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', ...sx }} component="span">
      {showMessage ? (
        <span>Please wait, I&apos;m working on it...</span>
      ) : (
        <>
          <Box
            component="span"
            sx={{ ...animationStyles, animationDelay: '0s' }}
          />
          <Box
            component="span"
            sx={{ ...animationStyles, animationDelay: '0.2s' }}
          />
          <Box
            component="span"
            sx={{ ...animationStyles, animationDelay: '0.4s' }}
          />
        </>
      )}
    </Box>
  );
}