import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

// APP
const GuidancePage = lazy(() => import('src/pages/dashboard/guidance'));
const HomePage = lazy(() => import('src/pages/dashboard/home'));
const SettingsPage = lazy(() => import('src/pages/dashboard/settings'));
const PlanningPage = lazy(() => import('src/pages/dashboard/planning'));
// USER
const UserProfilePage = lazy(() => import('src/pages/dashboard/user/profile'));
const UserCardsPage = lazy(() => import('src/pages/dashboard/user/cards'));
const UserListPage = lazy(() => import('src/pages/dashboard/user/list'));
const UserAccountPage = lazy(() => import('src/pages/dashboard/user/account'));
const UserCreatePage = lazy(() => import('src/pages/dashboard/user/new'));
const UserEditPage = lazy(() => import('src/pages/dashboard/user/edit'));

// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: '',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      {
        path: 'user',
        children: [
          { element: <UserListPage />, index: true },
          { path: ':id/profile', element: <UserProfilePage /> },
          { path: 'cards', element: <UserCardsPage /> },
          { path: 'list', element: <UserListPage /> },
          { path: 'new', element: <UserCreatePage /> },
          { path: ':id/edit', element: <UserEditPage /> },
          { path: 'account', element: <UserAccountPage /> },
        ],
      },
      // { path: 'guidance/:id', element: <GuidancePage /> },
      // { path: 'guidance', element: <GuidancePage /> },
      { path: 'home', element: <HomePage /> },
      { path: 'settings', element: <SettingsPage /> },
      { path: 'planning', element: <PlanningPage /> },
      { path: 'chat', element: null },
    ],
  },
];
