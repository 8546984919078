import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useBoolean } from 'src/hooks/use-boolean';
import { paths } from 'src/routes/paths';
import { RouterLink } from 'src/routes/components';
import { useSearchParams, useRouter } from 'src/routes/hook';
import { PATH_AFTER_LOGIN } from 'src/config-global';
import { useAuthContext } from 'src/auth/hooks';
import { Box, Button, Divider } from '@mui/material';
import mixpanel from 'mixpanel-browser';
import LoadingButton from '@mui/lab/LoadingButton';
import Link from '@mui/material/Link';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import Iconify from 'src/components/iconify';
import FormProvider, { RHFTextField } from 'src/components/hook-form';


export default function JwtRegisterView() {
  
  const { register, loginWithXero, loginWithQB } = useAuthContext();

  const router = useRouter();

  const [errorMsg, setErrorMsg] = useState('');

  const searchParams = useSearchParams();

  const returnTo = searchParams.get('returnTo');

  const password = useBoolean();

  const showFullForm = useBoolean(false);

  const RegisterSchema = Yup.object().shape({
    firstName: Yup.string().required('First name required'),
    lastName: Yup.string().required('Last name required'),
    orgName: Yup.string().required('Organization name required'),
    email: Yup.string().required('Email is required').email('Email must be a valid email address'),
    password: Yup.string().required('Password is required').matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&()])[A-Za-z\d@$!%*#?&()]{8,}$/,
      'Password must be 8 characters long and contain at least one letter, one number, and one special case character'
    ),
    confirmPassword: Yup.string()
      .required('Confirm password is required')
      .oneOf([Yup.ref('password')], 'Passwords must match'),
  });

  const defaultValues = {
    firstName: '',
    lastName: '',
    orgName: '',
    email: '',
    password: '',
    confirmPassword: '',
  };

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      await register?.(data.email, data.password, data.orgName, data.firstName, data.lastName);

      mixpanel.track("registered", {
        "email_address": data.email,
      });
      router.push(returnTo || PATH_AFTER_LOGIN);
    } catch (error) {
      console.error(error);
      reset({
        ...data,
        password: '',
        confirmPassword: '',
      });
      setErrorMsg(typeof error === 'string' ? error : error.message);
    }
  });

  const handleXeroLogin = async () => {
    try {
      await loginWithXero?.();
    } catch (error) {
      console.error(error);
    }
  };

  const handleQBLogin = async () => {
    try {
      await loginWithQB?.();
    } catch (error) {
      console.error(error);
    }
  };

  const onContinueButtonClicked = () => {
    const email = methods.getValues('email');
    mixpanel.track("new_lead_from_register", {
      "email_address": email,
    });
    showFullForm.onTrue();
  }

  const renderHead = (
    <Stack spacing={2} sx={{ mb: 3, position: 'relative' }}>
      <Typography variant="h4">Get started absolutely free</Typography>

      <Stack direction="row" spacing={0.5}>
        <Typography variant="body2"> Already have an account? </Typography>

        <Link href={paths.auth.login} component={RouterLink} variant="subtitle2">
          Sign in
        </Link>
      </Stack>
    </Stack>
  );

  const renderTerms = (
    <Typography
      component="div"
      sx={{ color: 'text.secondary', mt: 2.5, typography: 'caption', textAlign: 'center' }}
    >
      {'By signing up, I agree to '}
      <Link underline="always" color="text.primary" href="https://www.myoctopus.ai/terms_of_service" target="_blank">
        Terms of Service
      </Link>
      {' and '}
      <Link underline="always" color="text.primary" href="https://www.myoctopus.ai/privacy_policy" target="_blank">
        Privacy Policy
      </Link>
      .
    </Typography>
  );

  const renderForm = (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Stack spacing={2.5}>
        {!!errorMsg && <Alert severity="error">{errorMsg}</Alert>}
        
        <RHFTextField name="email" label="Email address" />

        {!showFullForm.value && (
          <Button
            onClick={onContinueButtonClicked}
            fullWidth
            color="inherit"
            size="large"
            variant="contained"
          >
            Continue
          </Button>
        )}

        {showFullForm.value && (<>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <RHFTextField name="firstName" label="First name" />
            <RHFTextField name="lastName" label="Last name" />
          </Stack>

          <RHFTextField name="orgName" label="Organization name" />

          <RHFTextField
            name="password"
            label="Password"
            type={password.value ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={password.onToggle} edge="end">
                    <Iconify icon={password.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <RHFTextField
            name="confirmPassword"
            label="Confirm New Password"
            type={password.value ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={password.onToggle} edge="end">
                    <Iconify icon={password.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <LoadingButton
            fullWidth
            color="inherit"
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            Create account
          </LoadingButton>
        </>)}
      </Stack>
    </FormProvider>
    
  );

  const renderRegisterOptions = 
    (<div>
      <Divider
        sx={{
          my: 2.5,
          typography: 'overline',
          color: 'text.disabled',
          '&::before, ::after': {
            borderTopStyle: 'dashed',
          },
        }}
      >
        OR
      </Divider>
      <Stack direction="row" alignContent="center" justifyContent="center" spacing={2} height={35}>
        {/* <Button
          fullWidth
          id="xero_button"
          color="inherit"
          variant="outlined"
          onClick={handleXeroLogin}
          sx={{backgroundColor: 'white', m: 0, p: 0,
            overflow: 'hidden', // Ensure the edges of the image are clipped to the border radius
            ':hover': {
              bgcolor: 'transparent' // Optional: Set background color on hover if needed
            }
          }}
        >
          <Box component="img" src="/assets/icons/auth/xero-connect-blue.svg" 
            sx={{
              position: 'absolute', // Absolutely position the image
              top: 0, // Align to the top of the button
              left: 0, // Align to the left of the button
              width: '100%', // Match the width of the button
              height: '100%', // Match the height of the button
              objectFit: 'cover', // Ensure the image covers the button area without stretching
              borderRadius: 'inherit',
            }}/>
        </Button> */}
        <Button
          id="qb_button"
          color="inherit"
          variant="outlined"
          onClick={handleQBLogin}
          sx={{
            backgroundColor: 'white',
            m: 0,
            p: 0,
            width: 200,
            overflow: 'hidden', // Ensure the edges of the image are clipped to the border radius
            ':hover': {
              bgcolor: 'transparent' // Optional: Set background color on hover if needed
            }
          }}
        >
          <Box component="img" src="/assets/icons/auth/Sign_in_blue_btn_med_default.svg" 
            sx={{
              position: 'absolute', // Absolutely position the image
              top: 0, // Align to the top of the button
              left: 0, // Align to the left of the button
              width: '100%', // Match the width of the button
              height: '100%', // Match the height of the button
              objectFit: 'cover', // Ensure the image covers the button area without stretching
              borderRadius: 'inherit',
            }}/>
        </Button>
      </Stack>
    </div>)

  return (
    <>
      {renderHead}

      {renderForm}

      {renderRegisterOptions}

      {renderTerms}
    </>
  );
}
