import { m } from 'framer-motion';
// @mui
import { Theme, SxProps } from '@mui/material/styles';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Badge, { badgeClasses } from '@mui/material/Badge';
// components
import Iconify from 'src/components/iconify';
import { varHover } from 'src/components/animate';
import { useSettingsContext } from 'src/components/settings';

// ----------------------------------------------------------------------

type Props = {
  sx?: SxProps<Theme>;
};

export default function SupportButton({ sx }: Props) {
  
  const onClick = () => {
    window.open('https://uttermost-slicer-aed.notion.site/Support-Documentation-for-Octopus-ai-ca56b132944e4ff9a2af1bb7dbea76f7?pvs=4');
  }

  return (
    <Box
      component={m.div}
      transition={{
        duration: 12,
        ease: 'linear',
        repeat: Infinity,
      }}
    >
      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        aria-label="settings"
        onClick={() => onClick()}
        sx={{
          width: 40,
          height: 40,
        }}
      >
        <Iconify icon="ic:baseline-help-outline" width={24} />
      </IconButton>
    </Box>
  );
}
