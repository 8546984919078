import numeral from 'numeral';
import { getCurrencySymbol } from './currency';

// ----------------------------------------------------------------------

type InputValue = string | number | null;

export function fNumber(number: InputValue) {
  return numeral(number).format();
}

export function fFloat(number: InputValue) {
  return numeral(number).format('0.0');
}

export function fCurrency(number: InputValue, currencyCode: string = 'USD') {
  if (!number) return '';
  let numericValue: number;
  if (typeof number === 'string') {
    numericValue = parseFloat(number);
    if (Number.isNaN(numericValue)) {
      throw new Error('Number input must be a valid number or numeric string');
    }
  } else {
    numericValue = number; // We can safely assign it since we know it's a number
  }
  if (!currencyCode) currencyCode = 'USD';
  let formattedNumber = numeral(numericValue).format('0,0.00');

  // Check if the number is negative
  if (numericValue < 0) {
    // Remove the minus sign from the formatted number
    formattedNumber = formattedNumber.replace('-', '');
    // Place the minus sign to the left of the currency symbol
    return `-${getCurrencySymbol(currencyCode)}${formattedNumber}`;
  }
  return `${getCurrencySymbol(currencyCode)}${result(formattedNumber, '.00')}`;
}

export function fShortenCurrency(number: InputValue, currencyCode: string = 'USD') {
  if (!number) return '';
  let numericValue: number;
  if (typeof number === 'string') {
    numericValue = parseFloat(number);
    if (Number.isNaN(numericValue)) {
      throw new Error('Number input must be a valid number or numeric string');
    }
  } else {
    numericValue = number; // We can safely assign it since we know it's a number
  }
  if (!currencyCode) currencyCode = 'USD';
  let formattedNumber = numeral(numericValue).format('0,0.00a');

  // Check if the number is negative
  if (numericValue < 0) {
    // Remove the minus sign from the formatted number
    formattedNumber = formattedNumber.replace('-', '');
    // Place the minus sign to the left of the currency symbol
    return `-${getCurrencySymbol(currencyCode)}${formattedNumber}`;
  }
  return `${getCurrencySymbol(currencyCode)}${result(formattedNumber, '.00')}`;
}

export function fPercent(number: InputValue) {
  const format = numeral(Number(number) / 100).format('0.0%');

  return result(format, '.0');
}

export function fShortenNumber(number: InputValue) {
  const format = numeral(number).format('0.00a');

  return result(format, '.00');
}

export function fData(number: InputValue) {
  const format = numeral(number).format('0.0 b');

  return result(format, '.0');
}

function result(format: string, key = '.00') {
  const isInteger = format.includes(key);

  return isInteger ? format.replace(key, '') : format;
}
