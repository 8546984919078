import { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import Avatar from '@mui/material/Avatar';
import Container from '@mui/material/Container';
import { DataGridPremium, GridRowParams } from '@mui/x-data-grid-premium';
import { useSnackbar } from 'src/components/snackbar';
import { approveInvoice } from 'src/api/invoices'
import { IInvoice } from 'src/types/invoice';
import { fNumber } from 'src/utils/format-number';
import moment from "moment";
import { Button, Link, ListItemText, Stack, Typography } from '@mui/material';
import Iconify from 'src/components/iconify/iconify';
import { ConfirmDialog } from 'src/components/custom-dialog';
import { useBoolean } from 'src/hooks/use-boolean';


// ----------------------------------------------------------------------

type Props = {
  invoices: IInvoice[],
  onInvoicesChanged: (invoices: IInvoice[]) => void,
  orgId: string
};

export default function InvoiceListView({invoices, onInvoicesChanged, orgId}: Props) {
  const { enqueueSnackbar } = useSnackbar();
  
  const confirm = useBoolean();
  const [selectedInvoiceId, setSelectedInvoiceId] = useState<string>();
  const [rows, setRows] = useState<IInvoice[]>(invoices);
  
  const onApproveRow = async (): Promise<void> => {
    if (!selectedInvoiceId) return;
    approveInvoice(orgId, selectedInvoiceId).then((res) => {
      enqueueSnackbar('Bill approved!');
      const filteredInvoices = invoices.filter((invoice: IInvoice) => invoice.id !== selectedInvoiceId);
      setRows(filteredInvoices);
      onInvoicesChanged(filteredInvoices);
    }).catch((e) => {
      console.log(e);
    });
  }

  const getActions = (invoice:IInvoice) => {
    const result:any[] = [];
    // if (invoice.status === "SUBMITTED") {
    //   result.push(
    //   <GridActionsCellItem color='success' icon={<Iconify icon="line-md:confirm-circle" />}
    //     onClick={() => {
    //       setSelectedInvoiceId(invoice.id);
    //       confirm.onTrue();
    //     }} label="Approve" />,
    //   );
    // }
    return result;
  }

  return (
      <Container disableGutters>
        <Card >
          <DataGridPremium
            rows={invoices}
            disableColumnSelector
            getAggregationPosition={(groupNode) => groupNode == null ? null : 'inline'}
            pageSizeOptions={[5, 10, 25]}                  
            initialState={{
              rowGrouping: {
                model: ['currency'],
              },
              aggregation: {
                model: {
                  total: 'sum',
                  totalTax: 'sum',
                },
              },
              columns: {
                // Other hidden columns
                columnVisibilityModel: { currency: false },
              },
              pagination: { paginationModel: { pageSize: 5 } },
            }}
            columns={[
              {
                headerName: "Currency",
                filterable: false,
                field: "currency",
                type: "string",
                width: 50,
                valueGetter: ({ value }) => value && new Date(value),
              },
              {
                headerName: "Contact",
                filterable: false,
                field: "contact",
                width: 300,
                renderCell: (params) => {
                  if (params.rowNode.type === 'group') {
                    return params.value;
                  }
                  return ( 
                    <Stack direction='row' sx={{ display: 'flex', maxWidth: 200 }}>
                      <Avatar alt={params.value} sx={{ mr: 2 }}>
                        {params.value.charAt(0).toUpperCase()}
                      </Avatar>
            
                      <ListItemText
                        disableTypography
                        primary={
                          <Link href={params.row.link} target="_blank">
                            <Typography variant="body2" noWrap>
                              {params.value}
                            </Typography>
                          </Link>
                        }
                        secondary={
                          <Typography maxWidth={300} sx={{ color: 'text.disabled', 
                              wordWrap: 'break-word', fontSize: 12 }}>
                            {params.row.insight}
                          </Typography>
                        }
                      />
                    </Stack>)
                }
              },
              {
                headerName: "Issue date",
                filterable: false,
                field: "createDate",
                type: "dateTime",
                width: 110,
                valueGetter: ({ value }) => value && new Date(value),
                valueFormatter: ({ value }) => value ? moment(value).format("DD MMM YYYY") : null,
              },
              {
                headerName: "Due date",
                filterable: false,
                field: "dueDate",
                type: "dateTime",
                width: 110,
                valueGetter: ({ value }) => value && new Date(value),
                valueFormatter: ({ value }) => value ? moment(value).format("DD MMM YYYY") : null,
              },
              {
                headerName: "Total Amount",
                type: "number",
                filterable: false,
                field: "total",
                width: 110,
                renderCell: (params) => {
                  if (params.rowNode.type === 'group') {
                    return fNumber(params.value);
                  }
                  return `${params.row.currency + fNumber(params.value)}` || "";
                }
              },
              {
                headerName: "Tax",
                type: "number",
                filterable: false,
                field: "totalTax",
                width: 110,
                renderCell: (params) => {
                  if (params.rowNode.type === 'group') {
                    return fNumber(params.value);
                  }
                  return `${params.row.currency + fNumber(params.value)}` || ""
                }
              },
              {
                headerName: "Status",
                filterable: false,
                field: "status",
                width: 110,
              },
              {
                field: 'actions',
                type: 'actions',
                getActions: (params: GridRowParams) => getActions(params.row)
              }
            ]}
          />

          <ConfirmDialog
            open={confirm.value}
            onClose={confirm.onFalse}
            title="Approval"
            content="Are you sure you want to approve?"
            action={
              <Button variant="contained" color="success" 
                onClick={
                  () => onApproveRow().then(() => 
                    confirm.onFalse()
                  ).catch(() =>
                    confirm.onFalse()
                  )
                }>
                Approve
              </Button>
            }
          />
      </Card>
    </Container>
  );
}
