import { createContext, useMemo, useState } from 'react';

interface DebugContextType {
    isDebug: boolean;
    setIsDebug: (value: boolean) => void;
}

const initialValue = {
    isDebug: false,
    setIsDebug: (value: boolean) => {}
}

export const DebugContext = createContext<DebugContextType>(initialValue);

interface Props {
    children: React.ReactNode;
}

const DebugProvider: React.FC<Props> = ({ children }: Props) => {

    const [isDebug, setIsDebug] = useState<boolean>(false);

    const memoizedValue = useMemo(
        () => ({ 
            isDebug, 
            setIsDebug
        })
    , [isDebug, setIsDebug]);

    return (
        <DebugContext.Provider value={memoizedValue} >
            {children}
        </DebugContext.Provider>
    );
};

export default DebugProvider;
