import { useCallback, useContext, useEffect, useState } from 'react';
import { HOST_API, WS_URL } from 'src/config-global';
import { useNavigate } from 'react-router';
import { Button, Card, CardContent, CardHeader, Grid, IconButton, Stack } from '@mui/material';
import { useAuthContext } from 'src/auth/hooks';
import { IChatConversation, IChatMessage, IChatParticipant } from 'src/types/chat';
import { useGetConversation } from 'src/api/chat';
import { AppContext } from 'src/context/app-context';
import { useBoolean } from 'src/hooks/use-boolean';
import mixpanel from 'mixpanel-browser';
import { paths } from 'src/routes/paths';
import ChatMessageInput from './chat-message-input';
import ChatMessageList from './chat-message-list';
import Iconify from '../iconify';
import ChatMessageItem from './chat-message-item';

// const iceBreakers = [
//   "I anticipate expenses that will impact my Runway.",
//   "Compare my P&L from the past two months.",
//   "Show me my top five expenses from last month.",
//   "Display my vendors' balances."
// ]

const iceBreakers = [
  "I foresee new expenses ahead",
  "I'm thinking of hiring staff",
  "I'm planning subscription sales",
  "I'm considering raising capital"
];

type Props = {
  isOneMessageMode: boolean
}

export default function Chat({isOneMessageMode} : Props) {
  
  const navigate = useNavigate();
  const isShowIceBreakers = useBoolean(false);
  const isShowMessage = useBoolean(false);
  const { user, initialized } = useAuthContext();
  const { selectedOrganizationId, systemMessageEmitter, messages, setMessages, participants, setParticipants } = useContext(AppContext);
  const { conversation: conversationVal } = useGetConversation(user?.id || "", selectedOrganizationId);
  const [ conversation, setConversation ] = useState<IChatConversation>();
  const [ webSocket, setWebSocket ] = useState<WebSocket>();
  
  useEffect(() => {
    setConversation(conversationVal);
  }, [conversationVal]);

  // Set the participants array (User and Bot)
  useEffect(() => {
    setParticipants(
      [
        {
          "status": "online",
          "id": user?.id || "1",
          "email": user?.email || "",
          "name": user ? `${user.first_name} ${user.last_name}` : 'Anonymous',
          "avatarUrl": "https://api-prod-minimal-v510.vercel.app/assets/images/avatar/avatar_2.jpg",
        },
        {
          "status": "online",
          "id": "0",
          "email": "support@myoctopus.ai",
          "name": user?.accountant.bot_display_name || "Octopus AI",
          "avatarUrl": user?.accountant.bot_logo_url || "/logo/logo.svg",
        }
      ]
    )
  }, [user, setParticipants]);

  // Set conversation messages 
  useEffect(() => {
    if (conversation?.messages) {
      // Filter out system messages that the client sends but the user should not see
      conversation.messages = conversation.messages.filter(
        (messageItem: IChatMessage) => messageItem.contentType !== 'system_message'
      )
      // Sort the messages by date
      const sorted_messages = conversation.messages.sort((a: IChatMessage, b: IChatMessage) => {
        if (!a.createdAt || !b.createdAt) return 0;
        const dateA = new Date(a.createdAt);
        const dateB = new Date(b.createdAt);
        return dateA.getTime() - dateB.getTime();
      });
      setMessages(sorted_messages);
    }
  }, [conversation, setMessages]);

  const handleNavigation = useCallback((message: IChatMessage) => {
    if(message.body === 'reload_assumptions') {
      navigate(paths.planning.root);
    }
  }, [navigate]);

  // Opens the websocket and add listeners
  const connect = useCallback((attemptCount:number) => {
    // Define the WebSocket URL
    const ws:WebSocket = new WebSocket(`${WS_URL}${HOST_API}/websocket`);
    
    // Indicates if a message stream is open
    let streamedMessage: any = null;
    // Create a new WebSocket connection
    setWebSocket(ws);

    // "open" listener
    ws?.addEventListener('open', (event) => {});

    // "error" listener
    ws?.addEventListener('error', (wsError) => {});
    
    // "close" listener
    ws?.addEventListener('close', (event) => {
      // if websocket is closed, try to reconnect 10 times
      const reconnect = () => {
        if(attemptCount === 10) {
          window.document.location = "/";
          return;
        }
        setTimeout(() => {
          try {
            connect(attemptCount + 1); 
          } catch(e) {
            console.log(e);
          }
        }, 1000);
      };
      reconnect();    
    });

    // "message" listener
    ws?.addEventListener('message', (event) => {
      if (!(event && event.data))
        return;
      const message = JSON.parse(event.data);
      switch (message.contentType) {
        case "system":
          systemMessageEmitter.emit(message.body);
          handleNavigation(message);
          break;
        case "no_data_sources":
          setMessages((prevMessages:any) => {
            const result = [...(
              prevMessages.filter(
                (messageItem: IChatMessage) => messageItem && messageItem.contentType !== 'typing_indicator' && messageItem.mimeType !== "stream"
              )
            ), message];
            return result;
          });
          break;
        case 'session_id':
          localStorage.setItem("sessionId", message.body);
          break;
        case 'multi_answer_question':
          setMessages((prevMessages:any) => {
            const result = [...(
              prevMessages.filter(
                (messageItem: IChatMessage) => messageItem && messageItem.contentType !== 'typing_indicator' && messageItem.mimeType !== "stream"
              )
            ), message];
            return result;
          });
          break;
        case 'end_stream': // When the streamed message has reached to its end, its time to add it to the messages
          streamedMessage = null;
          setMessages((prevMessages:any) => {
            const result = prevMessages.map((obj:any) => ({ ...obj, mimeType: "" }));
            return result;
          });
          break;
        default:
          // A stream message is recieved in parts and its time is "stream"
          streamedMessage = {
            id: streamedMessage?.id || message.id,
            body: (streamedMessage?.body || "") + message.body,
            createdAt: streamedMessage?.createdAt || message.createdAt,
            mimeType: "stream",
            senderId: streamedMessage?.senderId || message.senderId,
            orgId: streamedMessage?.orgId || message.orgId,
            contentType: streamedMessage?.contentType || message.contentType,
            assistant: streamedMessage?.assistant || message.assistant,
          };
          setMessages((prevMessages:any) => {
            if (!streamedMessage) return prevMessages;
            const result = [...(
              prevMessages.filter(
                (messageItem: IChatMessage) => messageItem && messageItem.contentType !== 'typing_indicator' && messageItem.mimeType !== "stream"
              )
            ), streamedMessage];
            return result;
          });
          break;
      }
    });
  }, [systemMessageEmitter, handleNavigation, setMessages]);

  // Upon component creation, call "connect" to open a websocket to the server
  useEffect(() => {
    if (!initialized) return;
    try {  
      connect(0);
    } catch(e) {
      console.log(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialized]);

  // Adds a typing animation to the bottom of the chat
  const showTypingIndicator = useCallback(() => {
    isShowMessage.onTrue();
    const messageTypingIndicator = {
      id: "-1",
      body: "",
      createdAt: new Date(),
      senderId: "0",
      orgId: "0",
      contentType: "typing_indicator",
    }
    setMessages((prevMessages:any) => {
      const result = [...prevMessages, messageTypingIndicator];
      return result;
    });
  }, [isShowMessage, setMessages])
  
  // Sends message to the server
  const sendMessage = useCallback(async (message: IChatMessage) => {
    while (webSocket?.readyState !== WebSocket.OPEN) {
      /* eslint-disable-next-line no-await-in-loop */
      await new Promise(resolve => setTimeout(resolve, 200));
    }
    message.senderId = user!.id;
    message.orgId = selectedOrganizationId;
    message.createdAt = new Date();
    const message_json = {
      "type": message.contentType,
      "org_id": selectedOrganizationId,
      "assistant": message.assistant,
      "mime_type": message.mimeType,
      "file_name": message.fileName,
      "index": message.index,
      "body": message.body,
    }
    if (message.contentType === "text") {
      setMessages((prevMessages:any) => [...prevMessages, message]);
    }
    webSocket?.send(JSON.stringify(message_json));
    showTypingIndicator();
  }, [selectedOrganizationId, user, webSocket, showTypingIndicator, setMessages]);

  useEffect( () => {
    systemMessageEmitter.on("send_message", sendMessage);
    return () => {
      systemMessageEmitter.off("send_message", sendMessage);
    };
  }, [systemMessageEmitter, sendMessage]);

  const onQuestionAnswered = (index: number, answer: string) => {
    sendMessage({
      "index": index,
      "assistant": "planning_assistant",
      "contentType": "multi_answer_question",
      "body": answer
    });
  }

  const sendIceBreaker = (question: string) => {
    sendMessage({
      "body": question,
      "contentType": "text",
    });
  }
  
  return participants ? (
    <Stack
      sx={{
        position: "fixed",
        alignItems: "center",
        bottom: 0,
      }}
    >
      {!isOneMessageMode && <ChatMessageList
        onMessageChanged={() => console.log("MessagesListChanged")}
        messages={messages}
        participants={participants}
        onQuestionAnswered={onQuestionAnswered}
      />}
      <Card
        sx={{
          zIndex: 1000,
          height: isShowIceBreakers.value ? '200px' : '0px',
          borderRadius: 0,
          position: 'absolute',
          bottom: 85,
          m: 'auto',
          px: 'autp',
          transition: 'height 0.3s ease-in-out', // Customize this as needed
          backgroundColor: 'primary.default',
        }}
      >
        <CardHeader
          title="Ice Breakers"
          action={
            <IconButton onClick={() => {
              mixpanel.track("ice_breakers_closed", {
                "user_id": user?.id,
                "user_name": `${user?.first_name} ${user?.last_name}`, 
              });
              isShowIceBreakers.onFalse();
            }}>
              <Iconify icon="solar:close-circle-bold" />
            </IconButton>
          }
        />
        <CardContent>
          <Grid container sx={{justifyContent: 'space-evenly'}}>
            {iceBreakers.map((question, index) => 
              <Grid item xs={6} key={index} md={5} sx={{fontSize: 10, pb: 1}}>
                <Button 
                  variant='outlined'
                  sx={{ font: 'inherit', fontWeight: "bold", fontSize: 12, width: "100%", height: 40, mb: '5px', color: (theme) => theme.palette.secondary.darker }}
                    onClick={() => {
                    // Track login event
                    mixpanel.track("ice_breaker_message", {
                      "user_id": user?.id,
                      "user_name": `${user?.first_name} ${user?.last_name}`,
                      "question": question, 
                    });
                    isShowIceBreakers.onFalse();
                    sendIceBreaker(question);
                  }}>
                  {question}
                </Button>
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
      {messages?.length > 0 && <Card
        sx={{
          width: '100%',
          height: (isOneMessageMode && isShowMessage.value) ? undefined : '0px',
          borderRadius: 0,
          position: 'absolute',
          bottom: 85,
          m: 'auto',
          px: 'autp',
          transition: 'height 0.3s ease-in-out', // Customize this as needed
          backgroundColor: 'primary.default',
        }}
      >
        <Stack direction='row' gap={1} sx={{
              position: 'absolute',
              top: 8,
              right: 8,
              zIndex: 100,
            }}>
          <IconButton onClick={() => {
            mixpanel.track("expand_chat_clicked", {
              "user_id": user?.id,
              "user_name": `${user?.first_name} ${user?.last_name}`, 
            });
            navigate(paths.chat.root)
          }}>
            <Iconify icon="pepicons-print:expand" />
          </IconButton>
          <IconButton onClick={() => {
            mixpanel.track("close_compact_chat_clicked", {
              "user_id": user?.id,
              "user_name": `${user?.first_name} ${user?.last_name}`, 
            });
            isShowMessage.onFalse();
          }}>
            <Iconify icon="solar:close-circle-bold" />
          </IconButton>
        </Stack>
        <CardContent sx={{maxHeight: '60vh', overflow: 'auto'}}>
          <ChatMessageItem
            message={messages[messages.length - 1]}
            onMessageChanged={() => {}}
            onQuestionAnswered={onQuestionAnswered}
            participants={participants}
            onOpenLightbox={() => {}}
          />
        </CardContent>
      </Card>}
      <ChatMessageInput
        onShowIceBreakers={() => {
          mixpanel.track("ice_breakers_opened", {
            "user_id": user?.id,
            "user_name": `${user?.first_name} ${user?.last_name}`, 
          });
          isShowMessage.onFalse();
          isShowIceBreakers.onTrue();
        }}
        onMessageSend={sendMessage}
        onStop={() => {}}
        orgId={selectedOrganizationId}
      />
    </Stack>
  ) : null;
}
