// with this code axios does not redirect the browser on 3xx codes 

import axios, { AxiosRequestConfig } from 'axios';
// config
import { HOST_API } from 'src/config-global';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: HOST_API, maxRedirects: 5 });

axiosInstance.interceptors.response.use(
  (response) => {
    if (response.data?.redirectUrl) {
      window.location.href = response.data.redirectUrl;
    }
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      if (window.location.pathname !== '/analytics' && window.location.pathname !== '/' && !window.location.pathname.includes('/auth')) {
        window.location.href = '/';
      }
    }
    return Promise.reject((error.response && error.response.data) || 'Something went wrong')
  }
);

export default axiosInstance;

// ----------------------------------------------------------------------

export const fetcher = async (args: string | [string, AxiosRequestConfig]) => {
  const [url, config] = Array.isArray(args) ? args : [args];
  const res = await axiosInstance.get(url, { ...config });
  return res.data;
};

// ----------------------------------------------------------------------

export const poster = async (args: string | [string, AxiosRequestConfig], data: any) => {
  const [url, config] = Array.isArray(args) ? args : [args];
  const res = await axiosInstance.post(url, data, { ...config });
  return res.data;
};

// ----------------------------------------------------------------------

const ROOTS = {
  CHAT: '/chat',
  USERS: '/users',
  ORGANIZATIONS: '/organizations',
  ACCOUNTANTS: '/accountants',
  INVOICES: '/invoices',
  PAYMENTS: '/payments',
  BANK_ACCOUNTS: '/bank-accounts',
};

export const endpoints = {
  chat: {
    root: ROOTS.CHAT,
    favoriteQuestion: `${ROOTS.CHAT}/favorite_questions`,
    scheduleQuestion: `${ROOTS.CHAT}/scheduled_questions`,
    contacts: `${ROOTS.CHAT}/contacts`,
    sampleQuestions: `${ROOTS.CHAT}/sample_questions`,
    unreadMessages: `${ROOTS.CHAT}/unread_messages`,
  },
  users: {
    root: ROOTS.USERS,
    user_by_id: (id: string) => `${ROOTS.USERS}/${id}`,
    me: `${ROOTS.USERS}/me`,
    invite: `${ROOTS.USERS}/invite`
  },
  accountants: {
    root: ROOTS.ACCOUNTANTS
  },
  organizations: {
    root: ROOTS.ORGANIZATIONS,
    set_kpis: (orgId:string) => `${ROOTS.ORGANIZATIONS}/${orgId}/set_kpis`,
    set_kpis_by_session: `${ROOTS.ORGANIZATIONS}/set_kpis_by_session`,
    remove_kpi: (orgId:string, kpiName:string) => `${ROOTS.ORGANIZATIONS}/${orgId}/remove_kpi/${kpiName}`,
    disconnect_org: (orgId:string) => `${ROOTS.ORGANIZATIONS}/${orgId}/disconnect`,
    download_financial_report: (orgId:string) => `${ROOTS.ORGANIZATIONS}/${orgId}/financial_report`,
  },
  payments: {
    root: ROOTS.PAYMENTS,
    createCheckoutSession: `${ROOTS.PAYMENTS}/create_checkout_session`,
  },
  bankAccounts: {
    root: ROOTS.BANK_ACCOUNTS,
    get_bank_accounts: (orgId:string) => `${ROOTS.BANK_ACCOUNTS}/${orgId}`,
    create_link_token: `${ROOTS.BANK_ACCOUNTS}/create_link_token`,
    exchange_public_token: (orgId:string) => `${ROOTS.BANK_ACCOUNTS}/exchange_public_token/${orgId}`
  },
  mailboxGmail: '/auth-mailbox/gmail',
  auth: {
    login: '/login',
    anonymousLogin: '/login/anonymous',
    register: '/register',
    verify: '/register/verify',
    password_reset: '/password/reset',
    password_change: '/password/change',
    login_with_google: '/login/google',
    login_with_xero: '/login/xero',
    logout: '/logout',
  },
  invoices: {
    root: ROOTS.INVOICES,
  },
};
