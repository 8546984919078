import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { useCallback, useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import { RouterLink } from 'src/routes/components';
import { useNavigate } from 'react-router';
import { useSearchParams, useRouter } from 'src/routes/hook';
import { PATH_AFTER_LOGIN } from 'src/config-global';
import { useBoolean } from 'src/hooks/use-boolean';
import { useAuthContext } from 'src/auth/hooks';
// components
import Iconify from 'src/components/iconify';
import FormProvider, { RHFTextField } from 'src/components/hook-form';
import { paths } from 'src/routes/paths';

// ----------------------------------------------------------------------

export default function LoginView() {
  const { login, anonymousLogin, loginWithXero, loginWithQB, loginWithGoogle } = useAuthContext();
  const router = useRouter();
  const [errorMsg, setErrorMsg] = useState('');
  const searchParams = useSearchParams();
  const returnTo = searchParams.get('returnTo');
  const isAutoLogin = searchParams.get('auto-login');
  const password = useBoolean();

  const LoginSchema = Yup.object().shape({
    email: Yup.string().required('Email is required').email('Email must be a valid email address'),
    password: Yup.string().required('Password is required'),
  });

  const defaultValues = {
    email: '',
    password: '',
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      await login?.(data.email, data.password);
      router.push(returnTo || PATH_AFTER_LOGIN);
    } catch (error) {
      console.error(error);
      reset({
        email: data.email,
        password: '',
      });
      setErrorMsg(typeof error === 'string' ? error : error.message);
    }
  });

  const onAnonymousLoginSubmit = useCallback(async () => {
    try {
      await anonymousLogin?.();
      router.push(paths.planning.root);
    } catch (error) {
      console.error(error);
    }
  }, [anonymousLogin, router]);

  const renderHead = (
    <Stack spacing={2} sx={{ mb: 5 }}>
      <Typography variant="h4">Sign in to Octopus AI</Typography>

      <Stack direction="row" spacing={0.5}>
        <Typography variant="body2">New user?</Typography>

        <Link component={RouterLink} href={paths.auth.register} variant="subtitle2">
          create an account
        </Link>

        <Typography variant="body2"> OR </Typography>

        <Link sx={{cursor: 'pointer'}} onClick={() => onAnonymousLoginSubmit()} variant="subtitle2">
          continue as guest
        </Link>

      </Stack>
    </Stack>
  );

  const handleXeroLogin = async () => {
    try {
      await loginWithXero?.();
    } catch (error) {
      console.error(error);
    }
  };

  const handleQBLogin = async () => {
    try {
      await loginWithQB?.();
    } catch (error) {
      console.error(error);
    }
  };

  const handleGoogleLogin = async () => {
    try {
      await loginWithGoogle?.();
    } catch (error) {
      console.error(error);
    }
  };

  const renderForm = (
    <Stack spacing={2.5}>
      {!!errorMsg && <Alert severity="error">{errorMsg}</Alert>}

      <RHFTextField name="email" label="Email address" />

      <RHFTextField
        name="password"
        label="Password"
        type={password.value ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={password.onToggle} edge="end">
                <Iconify icon={password.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'} />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <Link
        component={RouterLink}
        href={paths.auth.forgotPassword}
        variant="body2"
        color="inherit"
        underline="always"
        sx={{ alignSelf: 'flex-end' }}
      >
        Forgot password?
      </Link>
      
      <Button
        size="large"
        type="submit"
        variant="contained"
      >
        Login
      </Button>

    </Stack>
  );


  const renderLoginOption = (
    <div>
      <Divider
        sx={{
          my: 2.5,
          typography: 'overline',
          color: 'text.disabled',
          '&::before, ::after': {
            borderTopStyle: 'dashed',
          },
        }}
      >
        OR
      </Divider>

      <Stack direction="row" alignContent="center" justifyContent="center" spacing={2} height={35}>
        {/* <Button
          fullWidth
          id="xero_button"
          color="inherit"
          variant="outlined"
          onClick={handleXeroLogin}
          sx={{backgroundColor: 'white', m: 0, p: 0,
            overflow: 'hidden', // Ensure the edges of the image are clipped to the border radius
            ':hover': {
              bgcolor: 'transparent' // Optional: Set background color on hover if needed
            }
          }}
        >
          <Box component="img" src="/assets/icons/auth/xero-connect-blue.svg" 
            sx={{
              position: 'absolute', // Absolutely position the image
              top: 0, // Align to the top of the button
              left: 0, // Align to the left of the button
              width: '100%', // Match the width of the button
              height: '100%', // Match the height of the button
              objectFit: 'cover', // Ensure the image covers the button area without stretching
              borderRadius: 'inherit',
            }}/>
        </Button> */}
        {/* <Button
          id="qb_button"
          color="inherit"
          variant="outlined"
          onClick={handleGoogleLogin}
          sx={{
            backgroundColor: 'white',
            m: 0,
            p: 0,
            width: 200,
            overflow: 'hidden', // Ensure the edges of the image are clipped to the border radius
            ':hover': {
              bgcolor: 'transparent' // Optional: Set background color on hover if needed
            }
          }}
        >
          <Box component="img" src="/assets/icons/auth/ic_google.png" 
            sx={{
              position: 'absolute', // Absolutely position the image
              top: 0, // Align to the top of the button
              left: 0, // Align to the left of the button
              width: '100%', // Match the width of the button
              height: '100%', // Match the height of the button
              objectFit: 'cover', // Ensure the image covers the button area without stretching
              borderRadius: 'inherit',
            }}/>
        </Button> */}
        <Button
          id="qb_button"
          color="inherit"
          variant="outlined"
          onClick={handleQBLogin}
          sx={{
            backgroundColor: 'white',
            m: 0,
            p: 0,
            width: 200,
            overflow: 'hidden', // Ensure the edges of the image are clipped to the border radius
            ':hover': {
              bgcolor: 'transparent' // Optional: Set background color on hover if needed
            }
          }}
        >
          <Box component="img" src="/assets/icons/auth/Sign_in_blue_btn_med_default.svg" 
            sx={{
              position: 'absolute', // Absolutely position the image
              top: 0, // Align to the top of the button
              left: 0, // Align to the left of the button
              width: '100%', // Match the width of the button
              height: '100%', // Match the height of the button
              objectFit: 'cover', // Ensure the image covers the button area without stretching
              borderRadius: 'inherit',
            }}/>
        </Button>
      </Stack>
    </div>
  );

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>

      {renderHead}

      {renderForm}

      {renderLoginOption}

    </FormProvider>
  );
}
