import axios, { endpoints } from 'src/utils/axios';

export async function createCheckoutSession(): Promise<void> {

  const UPDATE_ACCOUNTANT_URL = `${endpoints.payments.createCheckoutSession}`;
  
  return axios.post(UPDATE_ACCOUNTANT_URL, {}, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}