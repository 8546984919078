import { useState, useEffect, useCallback, useContext } from 'react'; 
import { useNavigate } from 'react-router';
import { useTheme } from '@mui/material/styles';
import { Stack, AppBar, Toolbar, Button } from '@mui/material';
import { bgBlur } from 'src/theme/css';
import Logo from 'src/components/logo';
import { useAuthContext } from 'src/auth/hooks';
import { DebugContext } from 'src/context/debug-context';
import { UserRole } from 'src/types/user';
import { paths } from 'src/routes/paths';
import { HEADER } from '../config-layout';
import {
  AccountPopover,
  HeaderShadow,
  OrganizationsSelector,
} from '../_common';
import SupportButton from '../_common/support-button';
import UsersButton from '../_common/users-button';

// ----------------------------------------------------------------------

export default function Header() {

  const theme = useTheme();
  const { user } = useAuthContext();
  const { setIsDebug } = useContext(DebugContext);
  const navigate = useNavigate();

  const [ typedText, setTypedText ] = useState(''); 
  
  const handleKeyPress = useCallback((event:any) => {
    setTypedText((currentText) => {
      const updatedText = currentText + event.key;
      if (updatedText.endsWith('octopusdebug')) {
        setIsDebug(true);
      }
      return updatedText;
    });
  }, [setIsDebug]);
  
  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [typedText, handleKeyPress]);

  return (
    <AppBar
      sx={{
        height: HEADER.H_DESKTOP,
        zIndex: theme.zIndex.appBar + 1,
        ...bgBlur({
          color: theme.palette.background.default,
        }),
        transition: theme.transitions.create(['height'], {
          duration: theme.transitions.duration.shorter,
        }),
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          px: { lg: 5 },
        }}
      >
        <Logo sx={{ width: 80, minHeight: 30, mt: -1, ml: 0, mb: 1, pt: 0 }} />
        <Stack
          flexGrow={1}
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          spacing={{ xs: 0.5, sm: 1 }}
        >
        {/* {isDebug && <p>Debug mode is ON</p>} */}
          <>
            {/* {(Number(user?.role.id) === UserRole.SUPER_ADMIN || Number(user?.role.id) === UserRole.ACCOUNTANT) && <UsersButton />} */}
            {user?.organizations.length! > 1 && <OrganizationsSelector renderAsPopover />}
            <SupportButton/>
            {/* <SettingsButton/> */}
            {user?.status !== 'ANONYMOUS' && <AccountPopover />}
            {user?.status === 'ANONYMOUS' && 
              <Button variant='contained' sx={{backgroundColor: theme.palette.primary.darker}} onClick={() => navigate(paths.auth.register)}>Save</Button>
            }
          </>
        </Stack>
      </Toolbar>
      <HeaderShadow />
    </AppBar>
  );
}
