// @mui
import Box from '@mui/material/Box';
import { Modal } from '@mui/material';
import { useAuthContext } from 'src/auth/hooks';
import PricingView from 'src/sections/account/pricing-view';
import NavMini from './nav-mini';
import Main from './Main';
import Header from './header';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

export default function DashboardLayout({ children }: Props) {
  
  const { user } = useAuthContext();

  return (
    <>
      <Header/>

      <Box
        sx={{
          minHeight: 1,
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <NavMini/>

        <Main>{children}</Main>
      </Box>
      <Modal
        open={!(user && user.is_subscribed)}
        onClose={(e: Event) => e.stopPropagation()}
      >
        <Box sx={{ ...style, width: 1000 }}>
          <PricingView/>
        </Box>
      </Modal>
    </>
  )
}
