import { useEffect } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import 'simplebar-react/dist/simplebar.min.css';
import AppProvider from 'src/context/app-context';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Router from 'src/routes/sections';
import ThemeProvider from 'src/theme';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import mixpanel from 'mixpanel-browser';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { useScrollToTop } from 'src/hooks/use-scroll-to-top';
import ProgressBar from 'src/components/progress-bar';
import MotionLazy from 'src/components/animate/motion-lazy';
import { SettingsProvider, SettingsDrawer } from 'src/components/settings';
import { AuthProvider, AuthConsumer } from 'src/auth/context/jwt';
import { LicenseInfo } from '@mui/x-license-pro';
import { MIXPANEL_PROJECT_TOKEN, GOOGLE_CLIENT_ID, MUI_LICENSE } from 'src/config-global';
import SnackbarProvider from 'src/components/snackbar/snackbar-provider';
import DebugProvider from './context/debug-context';

// ----------------------------------------------------------------------

export default function App() {
  
  LicenseInfo.setLicenseKey(String(MUI_LICENSE));
  mixpanel.init(MIXPANEL_PROJECT_TOKEN || "", {debug: false, track_pageview: false, persistence: 'localStorage'});

  useEffect(() => {
    mixpanel.track("app_page_view", {});
  }, [])

  useScrollToTop();

  return (
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID || ""}>
      <AppProvider>
        <AuthProvider>
            <SettingsProvider
              defaultSettings={{
                themeMode: 'light', // 'light' | 'dark'
                themeDirection: 'ltr', //  'rtl' | 'ltr'
                themeContrast: 'default', // 'default' | 'bold'
                themeLayout: 'vertical', // 'vertical' | 'horizontal' | 'mini'
                themeColorPresets: 'purple', // 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red'
                themeStretch: true,
              }}
            >
              <ThemeProvider>
                <DebugProvider>
                  <MotionLazy>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <SnackbarProvider>
                        <SettingsDrawer />
                        <ProgressBar />
                        <AuthConsumer>
                          <Router />
                        </AuthConsumer>
                      </SnackbarProvider>
                    </LocalizationProvider>
                  </MotionLazy>
                </DebugProvider>
              </ThemeProvider>
            </SettingsProvider>
        </AuthProvider>
      </AppProvider>
    </GoogleOAuthProvider >
  );
}
