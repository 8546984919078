import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSearchParams } from 'react-router-dom';
// sections
import { NewPasswordView } from 'src/sections/auth';

// ----------------------------------------------------------------------

export default function NewPasswordPage() {

  const [searchParams] = useSearchParams();
  const email = searchParams.get('email');

  return (
    <>
      <Helmet>
        <title> New Password</title>
      </Helmet>

      <NewPasswordView email={email}/>
    </>
  );
}
