// components
import { useState } from 'react';
import { useTheme, useMediaQuery, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField, Typography, Link } from '@mui/material';
import { useAuthContext } from 'src/auth/hooks';
import mixpanel from 'mixpanel-browser';
import { useBoolean } from 'src/hooks/use-boolean';

// ----------------------------------------------------------------------

export default function LoginButtons() {

  const { loginWithXero, loginWithQB } = useAuthContext();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [email, setEmail] = useState<string>();
  const [service, setService] = useState<string>();

  const confirm = useBoolean();

  const handleXeroLogin = async () => {
    try {
      // Track login button clicked
      mixpanel.track("login_button", {
        "button": "xero",
      });
      
      await loginWithXero?.();
    } catch (error) {
      console.error(error);
    }
  };

  const handleNetSuiteLogin = async () => {
    try {
      // Track login button clicked
      mixpanel.track("login_button", {
        "button": "netsuite",
      });
      setService("netsuite");
      confirm.onTrue();
    } catch (error) {
      console.error(error);
    }
  };

  const handleQuickbooksLogin = async () => {
    try {
      // Track login button clicked
      mixpanel.track("login_button", {
        "button": "quickbooks",
      });
      await loginWithQB?.();
    } catch (error) {
      console.error(error);
    }
  };

  const handleMorningLogin = async () => {
    try {
      // Track login button clicked
      mixpanel.track("login_button", {
        "button": "morning",
      });
      setService("morning");
      confirm.onTrue();
    } catch (error) {
      console.error(error);
    }
  };
  
  return (
    <>
      <Stack direction='column'>
        <Stack direction='column' sx={{ p: 3 }}>
          
          <Typography variant={isMobile ? "body1" : "h6"} sx={{ color: '#5F5F5F', mb: 3, input: {fontSize: isMobile ? "0.675rem": "0.875rem"} }}>
            Unlock the magic of tailored financial strategies! 🌟 Connect your accounting system and let us transform your numbers into actionable growth plans. It&apos;s smart 🧠, secure 🔒, and seamless 🚀. Plus, we&apos;ll constantly monitor your KPIs, keeping you informed about the health and progress of your business. Stay ahead, effortlessly.
          </Typography>

          <Stack direction={isMobile ? 'column' : 'row'} gap={3}>

            <Button
                id="qb_button"
                color="inherit"
                variant="outlined"
                onClick={handleQuickbooksLogin}
                sx={{backgroundColor: 'white', ml: 1, mr: 1, p: 0}}
              >
                <Box component="img" src="/assets/icons/auth/Sign_in_blue_btn_med_default.svg" sx={{height: 40}}/>
            </Button>

            <Button
                color="inherit"
                variant="outlined"
                onClick={handleXeroLogin}
                sx={{backgroundColor: 'white', m: 0, p: 0}}
              >
                <Box component="img" src="/assets/icons/auth/xero-connect-blue.svg" sx={{height: 40}}/>
            </Button>
          </Stack>
          <hr/>
          <Stack direction={isMobile ? 'column' : 'row'} gap={1}>
              You can also 
              <Link
                color="primary"
                onClick={() => {
                  window.open('https://calendly.com/d/5cf-8qr-jzf/octopus-ai-demo', '_blank');
                  mixpanel.track("book_a_demo", {platform: 'calendly'});
                }}
              >
                Book a demo now!
              </Link>
              or
              <Link
                onClick={() => {
                  window.open('https://api.whatsapp.com/send/?phone=972544427809&text=Hi%2C+I+want+to+learn+more+about+Octopus.ai&type=phone_number&app_absent=0', '_blank');
                  mixpanel.track("book_a_demo", {platform: 'whatsapp'});
                }}
              >
                Contact us on Whatsapp
              </Link>


            {/* <Button
              color="inherit"
              variant="outlined"
              onClick={handleNetSuiteLogin}
              sx={{backgroundColor: 'white', m: 0, p: 1, height: '50px', fontSize: '0.775rem'}}
            >
              <Box sx={{m: 1}} width={35} height={35} component="img" src="/assets/icons/auth/netsuite.png"/>
              connect to NetSuite
            </Button> */}

            {/* <Button
              color="inherit"
              variant="outlined"
              onClick={handleMorningLogin}
              sx={{backgroundColor: 'white', m: 0, p: 1, height: '50px', fontSize: '0.775rem'}}
            >
              <Box sx={{m: 1}} width={35} height={35} component="img" src="/assets/icons/auth/morning.png"/>
              Connect to Morning
            </Button> */}
          </Stack>
        </Stack>
      
        {/* <Typography variant={isMobile ? 'body2' : 'body2'} sx={{ mt: 2 }}>
          Join us at Octopus.ai as a design partner to shape the future of AI-driven financial analytics. As an early adopter, you&apos;ll enjoy exclusive benefits and have a significant impact on product development. Interested? Schedule a brief introductory meeting through our Calendly link. Let&apos;s innovate together!
        </Typography> */}
      </Stack>

      <Dialog fullWidth maxWidth="xs" open={confirm.value} onClose={confirm.onFalse}>
        <DialogTitle sx={{ pb: 2 }}>Coming soon</DialogTitle>

        <DialogContent sx={{ typography: 'body2' }}>
          This service is under development. Leave your email to be notified when it&apos;s ready.
          <TextField
            sx={{mt: 2}}
            fullWidth
            onChange={(e) => setEmail(e.target.value)}
            label="Email"
          />
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" color="inherit" onClick={confirm.onFalse}>
            Cancel
          </Button>
          <Button variant="contained" color="success" 
            onClick={
              () => {
                confirm.onFalse();
                mixpanel.track("comming_soon", {
                  "service": service,
                  "email": email
                });
              }
            }>
            Send
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
