import * as Yup from 'yup';
import { useContext, useMemo } from 'react';
import { formatDistanceToNowStrict } from 'date-fns';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import mixpanel from 'mixpanel-browser';
import Typography from '@mui/material/Typography';
import { useTheme, alpha, useMediaQuery, Button, Dialog, DialogContent, DialogTitle, MenuItem } from '@mui/material';
import QRCode from "react-qr-code";
import { poster, endpoints } from 'src/utils/axios';
import { useSnackbar } from 'notistack';
import { useAuthContext } from 'src/auth/hooks';
import { IChatParticipant, IChatMessage } from 'src/types/chat';
import { IInvoice } from 'src/types/invoice';
import { TypingIndicator } from 'src/components/three-dots'
import { InvoiceListView } from 'src/sections/invoice/view';
import LoginButtons from 'src/components/login-buttons/login-buttons';
import MultiAnswerQuestion from 'src/components/multi-answer-question';
import { AppContext } from 'src/context/app-context';
import FormProvider, { RHFSelect } from 'src/components/hook-form';
import { LoadingButton } from '@mui/lab';
import { useBoolean } from 'src/hooks/use-boolean';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Markdown from './message-types/markdown/markdown';
import { useGetMessage } from './hooks';

type Props = {
  message: IChatMessage;
  participants: IChatParticipant[];
  onMessageChanged?: (message: IChatMessage) => void,
  onQuestionAnswered?: (index: number, answer: string, session_id: string) => void,
  onOpenLightbox?: (value: string) => void;
};

export default function ChatMessageItem({ message, participants, onMessageChanged, onQuestionAnswered, onOpenLightbox }: Props) {
  const { user } = useAuthContext();
  const { systemMessageEmitter } = useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();
  const showScheduleDialog = useBoolean();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  // const { revalidate: revalidateScheduled } = useGetScheduledQuestions(message.orgId!);
  // const { revalidate: revalidateFavorite } = useGetFavoriteQuestions();

  const { me, senderDetails, type } = useGetMessage({
    message,
    participants,
    currentUserId: user?.id,
  });

  const scheduledQuestionSchema = Yup.object().shape({
    schedule: Yup.string().required('Schedule description is required'),
    question: Yup.string().required(),
    orgId: Yup.string().required(),
  });

  const defaultValues = useMemo(
    () => ({
      schedule: "",
      question: message.body!,
      orgId: message.orgId!
    }),
    [message]
  );

  const methods = useForm({
    resolver: yupResolver(scheduledQuestionSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (formData) => {
    const { orgId, question, schedule } = formData;
    const data = new FormData();
    data.append('org_id', orgId);
    data.append('question', question);
    data.append('schedule', schedule);
    await poster(endpoints.chat.scheduleQuestion, data).then(() => {
      reset();
      // revalidateScheduled();
      enqueueSnackbar('Question scheduled successfully!');
      showScheduleDialog.onFalse();
    }).catch((error) => {
      enqueueSnackbar('Question scheduled failed, please try again!', {
        variant: 'error',
      });
      showScheduleDialog.onFalse();
    });
  });

  const onFavoriteClick = async () => {
    await poster(endpoints.chat.favoriteQuestion, {text: message.body}).then(() => {
      // revalidateFavorite();
      enqueueSnackbar('Question added to your favorites!');
    }).catch((error) => {
      enqueueSnackbar('Failed, please try again!', {
        variant: 'error',
      });
    });
  }

  const { firstName, avatarUrl } = senderDetails || { firstName: '', avatarUrl: '' };

  const { body, createdAt } = message;

  const onInvoicesChanged = (invoices: IInvoice[]) => {
    message.body = JSON.stringify(invoices);
    if (onMessageChanged) onMessageChanged(message);
  }

  const timeToLocal = (date: Date) => {
    const userOffset = new Date().getTimezoneOffset() * 60 * 1000;
    const utcDate = new Date(date);
    if (new Date(utcDate.getTime() - userOffset) > new Date())
      return new Date(utcDate.getTime());
    return new Date(utcDate.getTime() - userOffset);
  }

  const isWithinLastYear = (date: Date) => {
    const now = new Date();
    const oneYearAgo = new Date(now);
    oneYearAgo.setFullYear(now.getFullYear() - 1);

    // If the date is greater than or equal to oneYearAgo, it's within the last year.
    return date >= oneYearAgo;
  }

  const handleQuestionAnswered = (index: number, answer: string, session_id: string) => {
    if (onQuestionAnswered)  onQuestionAnswered(index, answer, session_id);
  }

  const renderInfo = (
    <Typography
      noWrap
      variant="caption"
      sx={{
        mb: 1,
        color: 'text.disabled',
        ...(!me && {
          mr: 'auto',
        }),
      }}
    >
      {!me && `${firstName}, `}
      {isWithinLastYear(timeToLocal(createdAt!)) && `${(formatDistanceToNowStrict(timeToLocal(createdAt!), {
        addSuffix: true,
      }))}`}
    </Typography>
  );

  const createButtonsFromText = (): JSX.Element[] => {
    const buttonRegex = /@@(.*?)@@/g;
    let match: RegExpExecArray | null;
    const buttons: JSX.Element[] = [];
    
    match = buttonRegex.exec(body)
    while (match !== null) {
      // Button element
      const buttonText = match[1];
      buttons.push(
        <Button
            variant='outlined'
            sx={{ mb: '5px', font: 'inherit', color: theme.palette.secondary.main }}
            key={`button-${match.index}`} onClick={() => sendMessage(buttonText)}>
          {buttonText}
        </Button>
      );
      match = buttonRegex.exec(body)
    }

    return buttons;
  };

  // Function to remove @@text@@ patterns from the body text
  const removePatternsFromBody = (): string => 
    body.replace(/@@.*?@@/g, '');

  const sendMessage = (text: string) => {
    mixpanel.track("auto_generated_reply_button", {
      "user_id": user?.id,
      "user_name": `${user?.first_name} ${user?.last_name}`,
    });
    systemMessageEmitter.emit("send_message", {
      "contentType": "text",
      "body": text,
      "assistant": message.assistant
    });
  } 

  const getOptionalButtons = () => {
    const buttons = createButtonsFromText();
    return buttons.length > 0 && (
      <Stack direction="row" spacing={2}>
        {buttons}
      </Stack>
    )
  }

  const renderBody = (
    <Stack
      sx={{
        p: 1.5,
        borderRadius: 1,
        typography: 'body2',
        // bgcolor: theme.palette.background.neutral,
        ...(me && {
          color: theme.palette.getContrastText(theme.palette.primary.lighter),
          bgcolor: alpha(theme.palette.primary.lighter, 0.9),
        }),
        ...(type === 'image' && {
          p: 0,
          bgcolor: 'transparent',
        }),
        ...((type === 'invoices' || type === 'bills' || type === 'bank_transactions') && {
          p: 0,
          bgcolor: 'transparent',
          width: "100%"
        }),
      }}
    >
      {type === 'image' && (
        <Box
          component="img"
          alt="attachment"
          src={body}
          onClick={() => {if (onOpenLightbox) onOpenLightbox(body)}}
          sx={{
            minHeight: 220,
            borderRadius: 1.5,
            cursor: 'pointer',
            '&:hover': {
              opacity: 0.9,
            },
          }}
        />
      )}
      {(type === 'invoices' || type === 'bills' || type === 'bank_transactions') && Array.isArray(JSON.parse(body)) && (
        <InvoiceListView onInvoicesChanged={onInvoicesChanged} orgId={message.orgId!} invoices={JSON.parse(body)} />
      )}
      {(type === 'text') && (
        <>
          <Markdown children={removePatternsFromBody()} />
          <br/>
          {getOptionalButtons()}
        </>
      )}
      {(type === 'typing_indicator') && (
        <TypingIndicator />
      )}
      {(type === 'login_options') && (
        <LoginButtons />
      )}
      {(type === 'welcome_message') && (
        <>
          <Markdown children={body} />
          {/* <br />
          <QRCode
            size={64}
            style={{ height: "auto", maxWidth: "100%", width: "20%" }}
            value={`https://wa.me/972535506606?text=Please connect my whatsapp ${user?.id}`}
            viewBox="0 0 1032 1032"
          /> */}
        </>
      )}
      {type === 'multi_answer_question' && (
        <MultiAnswerQuestion
          question={JSON.parse(body).question}
          answers={JSON.parse(body).answers}
          onAnswerSelected={(answer: string) => handleQuestionAnswered(JSON.parse(body).index, answer, JSON.parse(body).session_id)}
        />
      )}
    </Stack>
  );

  const renderActions = (
    <Stack
      direction="row"
      className="message-actions"
      sx={{
        pt: 0.5,
        // opacity: 0,
        top: '100%',
        right: 0,
        position: 'absolute',
        transition: () =>
          theme.transitions.create(['opacity'], {
            duration: theme.transitions.duration.shorter,
          }),
        ...(!me && {
          left: 'unset',
          right: 0,
          width: "100%",
        }),
      }}
    >
      {/* <IconButton size="small" onClick={() => showScheduleDialog.onTrue()}>
        <Iconify icon="mdi:clock-outline" width={16} />
      </IconButton>
      <IconButton size="small" onClick={(() => onFavoriteClick())}>
        <Iconify icon="uil:favorite" width={16} />
      </IconButton>
      <IconButton size="small">
        <Iconify icon="solar:trash-bin-trash-bold" width={16} />
      </IconButton> */}
    </Stack>
  );

  return (
    <Stack direction="row" justifyContent={me ? 'flex-end' : 'unset'}>
      {!isMobile && !me && <Avatar alt={firstName} src={avatarUrl} sx={{ width: 24, height: 24, mr: 2 }} />}
      <Stack alignItems="flex-end" width="100%">
        {renderInfo}
        
        <Stack
          direction={!me ? "row" : "row-reverse"}
          alignItems="center"
          sx={{
            width: "100%",
            position: 'relative',
            // '&:hover': {
            //   '& .message-actions': {
            //     opacity: 1,
            //   },
            // },
          }}
        >
          {renderBody}
          {me && renderActions}
        </Stack>
      </Stack>
        <Dialog
          fullWidth
          open={showScheduleDialog.value}
          onClose={showScheduleDialog.onFalse}>
          <DialogTitle sx={{ pb: 2 }}>Schedule question</DialogTitle>
          <DialogContent sx={{m: 2}}>
            <FormProvider methods={methods} onSubmit={onSubmit}>
              <Typography variant="body1">
                &quot;{message.body}&quot;
              </Typography>
              {/* <RHFTextField
                name="schedule"
                fullWidth
                variant="outlined"
                margin="normal"
                placeholder="at the second day of every month"
              /> */}
              <RHFSelect name="schedule" label="Question frequency" sx={{mt: 2}}>
                {[{label: "Daily", value: "DAILY"}, {label: "Weekly", value: "WEEKLY"}, {label: "Monthly", value: "MONTHLY"}].map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </RHFSelect>
              <Stack width="100%" justifyContent="end" direction="row" sx={{pt: 2}}>
                <Button variant="outlined" onClick={() => showScheduleDialog.onFalse()}>
                  Cancel
                </Button>
                <LoadingButton type="submit" variant="contained" color="primary" loading={isSubmitting} sx={{ml: 1}}>
                  Save
                </LoadingButton>
              </Stack>
            </FormProvider>
          </DialogContent>
        </Dialog>
    </Stack>
  );
}
