// components
import { useTheme, useMediaQuery, Typography, Stack, InputBase, IconButton, Button } from '@mui/material';
import { useCallback, useRef, useState } from 'react';
import mixpanel from 'mixpanel-browser';
import Iconify from '../iconify';

// ----------------------------------------------------------------------

type Props = {
  question: string;
  answers: string[];
  onAnswerSelected: (answer: string) => void;
};

export default function MultiAnswerQuestion({question, answers, onAnswerSelected} : Props) {

  const [selectedAnswer, setSelectedAnswer] = useState<string>("");
  const [otherSelected, setOtherSelected] = useState(false);
  const [otherAnswer, setOtherAnswer] = useState("");
  const otherInputField = useRef();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  
  const handleOtherSelected = useCallback(() => {
    // Track login event
    mixpanel.track("answer_clicked", {
      "answer": "other",
    });
    setSelectedAnswer("");
    setOtherSelected(true);
  }, []);

  const handleAnswerSelected = (value: string) => {
    onAnswerSelected(value);
    setSelectedAnswer(value);
  }

  const handleAnswerClicked = (answer: string) => {
    // Track login event
    mixpanel.track("answer_clicked", {
      "answer": answer,
    });
    setOtherSelected(false); 
    handleAnswerSelected(answer)
  }

  return (
    <Stack direction='column' sx={{ p: 3 }}>
      <Typography variant="h6" sx={{ mb: 3 }}>
        {question}
      </Typography>

      <Stack direction="row" gap={1}>
        {answers.map((answer, index) => (
          (answer === "other") ? (
            <Button
              color="primary"
              variant='outlined'
              value={-1}
              key={answers.length}
              onClick={handleOtherSelected}
            >
              Other
            </Button>
          ) : (
            <Button
              color="primary"
              variant='outlined'
              value={answer}
              key={index}
              onClick={() => handleAnswerClicked(answer)}
            >
              {answer}
            </Button>
          )
        ))}
      </Stack>

      {otherSelected && (
        <InputBase 
          sx={{ input: {fontSize: isMobile ? "0.675rem": "0.875rem"} }}
          ref={otherInputField}
          placeholder="Type your answer here"
          onChange={(event) => setOtherAnswer(event.target.value)}
          endAdornment={
            <IconButton onClick={async () => {
              if (otherAnswer) {
                await handleAnswerSelected(otherAnswer);
              }
            }}>
              <Iconify icon="eva:corner-right-down-outline" />
            </IconButton>
          }
          onKeyUp={async (event: React.KeyboardEvent<HTMLInputElement>) => {
            if (event.key === 'Enter') {
              if (otherAnswer) {
                await handleAnswerSelected(otherAnswer);
              }
            }
          }}
        />)}
    </Stack>
  );
}
