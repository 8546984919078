import Box, { BoxProps } from '@mui/material/Box';
import { alpha, useTheme } from '@mui/material/styles';
import { bgGradient } from 'src/theme/css';
import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { AppContext } from 'src/context/app-context';
import { paths } from 'src/routes/paths';
import { Stack } from '@mui/material';
import Chat from 'src/components/chat/chat';
import { useAuthContext } from 'src/auth/hooks';
import { HEADER } from '../config-layout';

// ----------------------------------------------------------------------

const SPACING = 8;

export default function Main({ children, sx, ...other }: BoxProps) {
  const theme = useTheme()
  const { user } = useAuthContext();
  const { selectedOrganizationId } = useContext(AppContext);
  // We also look for orgId in the address bar in case the user has opened it directly with selected org
  const [ windowHeight, setWindowHeight ] = useState(window.innerHeight);
  const location = useLocation();
  
  useEffect(() => {
    if(user?.status !== 'ANONYMOUS') return () => {};
    const confirmExit = (event: any) => {
      const message = "Your work is not yet saved, are you sure you want to exit?";
      event.returnValue = message; // Standard way to set a message in modern browsers
      return message; // Fallback for older browsers
    };

    window.addEventListener('beforeunload', confirmExit);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener('beforeunload', confirmExit);
    };
  }, [user]);

  // A useEffect that runs every time the screen dimensions change.
  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };

    // Set up the event listener
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // The empty dependencies array ensures this effect only runs once (like componentDidMount)

  return (
    <Stack
      component="main"
      direction='column'
      sx={{
        flexDirection: 'column',
        alignItems: 'center',
        // justifyContent: 'center',
        pt: `${HEADER.H_DESKTOP + SPACING}px`,
        width: '100vw',
        ...bgGradient({
          color: alpha(
            theme.palette.background.default,
            theme.palette.mode === 'light' ? 0.9 : 0.9
          ),
          imgUrl: '/assets/background/fin_background.png',
        }),
        backgroundRepeat: 'repeat',
        backgroundSize: '400px 400px',
        ...sx,
      }}
      {...other}
    >
      {!location.pathname.includes(paths.chat.root) && 
      <Box width="90%" sx={{px: 5}}>
        {children}
        {/* A placeholder to add some space at the bottom so the chat input won't hide it */}
        <Box sx={{height: 80}}/> 
      </Box>}
        
      <Chat isOneMessageMode={!location.pathname.includes(paths.chat.root)}/>
    </Stack>
  );
}
